import React, { useState } from "react";
import ExoticIngredients from "./Exotic";
import SameRecipe from "./SameRecipe";

interface DetailPageProps {
  details?: any;
  ingList: any;
  id: number;
  quantity: any;
  handleIncrement: (id: number, ingredient: any) => void;
  handleDecrement: (id: number, ingredient: any) => void;
  mainIngrediant: any;
  guestId:string;
}

const RecipePage: React.FC<DetailPageProps> = ({
  details,
  ingList,
  id,
  quantity,
  handleIncrement,
  handleDecrement,
  mainIngrediant,guestId,
}) => {
  const [selectedRecipe, setSelectedRecipe] = useState("Recipes");
  const handleCategoryClick = (categoryName: string) => {
    setSelectedRecipe(categoryName);
  };
  const convertToEmbedURL = (url: any) => {
    if (url) {
      const videoId = url.split("v=")[1]?.split("&")[0];
      return `https://www.youtube.com/embed/${videoId}`;
    }
  };

  // Style for hidden scrollbar

  //const hiddenScrollbarStyles = "scrollbar-none";
  //const storedCountry = localStorage.getItem('selectedCountry');
    const storedCode = localStorage.getItem('countryCode');

  return (
    <div className="lg:px-12 md:px-6 md:pt-1 lg:pt-1 bg-graybg">
      <div className="lg:flex grid-cols-1 md:grid-cols-2 rounded-lg md:gap-5 gap-12">
        <div className="hidden md:block lg:block col-span-1 space-y-6">
          <ExoticIngredients
            details={details}
            id={id}
            mainIngrediant={mainIngrediant}
            quantity={quantity}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            guestId={guestId}
          />
        </div>

        <div className="col-span-1">
          <div className="p-3 lg:p-12 md:p-5  border shadow-xl rounded-lg border-t-2">
            <div className="flex flex-row items-top justify-center mb-8 text-center font-sans">
              <button
                className={`md:hidden lg:hidden flex flex-col items-center px-4 pb-2 text-md font-bold ${
                  selectedRecipe === "Order Ingredients"
                    ? "text-red"
                    : "text-black"
                  }`}
                onClick={() => handleCategoryClick("Order Ingredients")}
              >
                Order Ingredients
                {selectedRecipe =="Order Ingredients" && (<div className="pb-1 border-b-[3px] rounded-sm w-[30px] border-red"/>)}
              </button>
              <button
                className={`flex flex-col items-center px-4 pb-2 text-md md:text-xl lg:text-xl font-bold ${
                  selectedRecipe === "Ingredients"
                    ? "text-red"
                    : "text-black"
                  }`}
                onClick={() => handleCategoryClick("Ingredients")}
              >
                Ingredients
                {selectedRecipe =="Ingredients" && (<div className="pb-1 border-b-[3px] rounded-sm w-[30px] border-red"/>)}
              </button>
              <button
                className={`flex flex-col items-center px-4 pb-2 text-md md:text-xl lg:text-xl font-bold ${
                  selectedRecipe === "Recipes"
                    ? "text-red"
                    : "text-black"
                  }`}
                onClick={() => handleCategoryClick("Recipes")}
              >
                Recipes
                {selectedRecipe =="Recipes" && (<div className="pb-1 border-b-[3px] rounded-sm w-[30px] border-red"/>)}
              </button>
            </div>

            {/* Recipe Content */}
            <div className="h-[600px] overflow-y-auto scrollbar-none">
              {selectedRecipe === "Recipes" && (
                <div>
                  {details?.videos !== null && (
                    <div className="w-full mb-6 ">
                      <iframe
                        key={details?.recipe?.videos}
                        className="w-full h-[260px] sm:h-[260px] md:h-[260px] lg:h-[260px] rounded-xl"
                        src={convertToEmbedURL(details?.recipe?.videos)}
                        title="Recipe Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>

                  )}

                  <div className="mb-4 font-sans leading-relaxed">
                    <div className="space-y-4">
                      {details?.recipe?.steps?.map(
                        (step: string, index: number) => {
                          const stepParts = step.split(". ");
                          return (
                            <div
                              key={index}
                              className="px-1 flex items-start font-sans leading-relaxed"
                            >
                              <span className="text-base mr-2 font-sans leading-relaxed">
                                {stepParts[0]}.
                              </span>
                              <span className="text-lg">
                                {stepParts.slice(1).join(". ")}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              )}

              {selectedRecipe === "Ingredients" && (
                // <div className="grid gap-4">
                   <ol className="flex-1 ml-6 list-decimal">
                  {details?.ingredients?.map((ingredient: any, index: number) => (
                        <li key={index} className="text-lg text-[#253D4E] mb-1 font-sans pb-3">
                          {ingredient.name.charAt(0).toUpperCase() + ingredient.name.slice(1)}
                        </li>
                  ))}
                  </ol>
                // </div>
              )}

              {selectedRecipe === "Order Ingredients" && (
                <div className="md:hidden lg:hidden col-span-1 space-y-6">
                  <ExoticIngredients
                    details={details}
                    id={id}
                    mainIngrediant={mainIngrediant}
                    quantity={quantity}
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                    guestId={guestId}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="p-2 lg:p-3 mb-8 mt-4 md:p-2 border rounded-lg border-t-2  shadow-lg  ">
            <SameRecipe ingList={ingList} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipePage;