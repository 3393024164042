import React, { useEffect, useState } from "react";
import error from "../../assets/images/error.png";
import {
  getAllCouponAsync,
  redeemCouponAsync,
} from "../../services/reducers/reciepeReducer";
import { useAppDispatch } from "../../hooks";
import im1 from "../../assets/images/cross.png";

interface CouponProps {
  isOpen: boolean;
  onClose: () => void;
  setDetail: any;
  total: number;
}
const CouponPopUp: React.FC<CouponProps> = ({ isOpen, onClose, setDetail, total }) => {
  // const [isDetailsOpen, setIsDetailsOpen] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const [getCoupons, setGetCoupons] = useState<any>([]);
  const userId = localStorage.getItem("userId");
  const [couponCode, setCouponCode] = useState("");
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  );
  const storedCode = localStorage.getItem('countryCode');

  useEffect(() => {
    const country = storedCode ?? "";
    dispatch(getAllCouponAsync(country)).then((res) => {
      if (res.payload) {
        setGetCoupons(res.payload?.data);
      }
    });
  }, []);

  // const couponCodes = getCoupons.map((coupon: any) => ({
  //   code: coupon.code,
  //   discount:
  //     coupon.discount_type === "percent"
  //       ? `${parseFloat(coupon.discount).toFixed(0)}%`
  //       : `${storedCode === "IN" ?"₹" : "£"}${parseFloat(coupon.discount).toFixed(0)}`,
  //   description: coupon.description,
  // }));

  const handleApply = (couponName: string) => {
    if (!couponName.trim()) {
      setValidationMessage("Please enter a coupon code.");
      return;
    }

    const request = {
      name: couponName,
      uid: userId,
    };

    dispatch(redeemCouponAsync(request)).then((res) => {
      if (res.payload && res.payload.success) {
        setDetail(res.payload.data);
        setValidationMessage(null);
        onClose();
      } else {
        setValidationMessage("Incorrect coupon");
      }
    });
  };

  if (!isOpen) return null;

  // const toggleDetails = (index: number) => {
  //   setIsDetailsOpen((prev) => (prev === index ? null : index));
  // };
  const handleClose = () => {
    setCouponCode("");
    setValidationMessage(null);
    onClose();
  };
  let offerText = "*Coupon is only valid for orders above ₹200.";
  if (storedCode === "UK") {
    offerText = "*Coupon is only valid for orders above £20.";
  } else if (storedCode === "IN") {
    offerText = "*Coupon is only valid for orders above ₹200.";
  }
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center oveflow-hidden">
      <div className="bg-white rounded-lg px-8 pb-8 lg:w-1/3 md:w-1/3 xl:w-1/3 w-3/4 overflow-hidden">
        <div className="flex justify-between items-center -mt-6 p-2 bg-[#FAFCFE] -ml-8 -mr-8 ">
          <p>
            <h2 className="text-xl font-bold mt-8  ml-2 ">Coupons</h2>
          </p>
          <button onClick={onClose}>
            <img
              src={im1}
              alt="Close"
              className="w-4 h-4 mt-8 mr-2"
            />
          </button>
        </div>
        <div className="text-xs text-[#FF3333] mt-2 -ml-4">
          {total < 200 ? offerText : ""}
        </div>
        <div className="flex items-center mt-8 mb-2">
          <input
            type="text"
            className="text-xs -ml-4 border border-[#AAAAAA] rounded-lg p-2 w-full text-stone-400"
            placeholder="Enter Coupon Code here"
            value={couponCode}
            onChange={(e) => {
              setCouponCode(e.target.value);
              setValidationMessage(null);
            }}
          />
          <div
            className={`-mr-2 ml-2 font-semibold cursor-pointer ${couponCode.trim() ? "text-green" : "text-stone-400"
              }`}
            onClick={() => handleApply(couponCode)}
          >
            APPLY
          </div>
        </div>
        {validationMessage && (
          <div className="flex items-center mt-2">
            <img src={error} alt="Error Icon" className="w-5 h-5 mr-2" />
            <p className="text-[#FF3333] text-lg">{validationMessage}</p>
          </div>
        )}
        {/* <div>
          <center className="mt-8 mb-3 font-semibold font-sans">
            OFFERS FOR YOU
          </center> */}
        {/* coupon container */}
        {/* <div className="h-96 overflow-y-auto scrollbar-hide">
            {couponCodes.map((couponCodes: any, index: number) => (
              <div key={index} className="py-3 rounded-lg border shadow-custom-red font-sans mb-10">
                <div>
                  <h5 className="px-8 py-2 font-semibold font-sans">{couponCodes.code}</h5>
                  <div className="px-8 flex flex-row gap-2 my-3 items-center">
                    <div>
                      <img src={discountsign} />
                    </div>
                    <div className="font-sans text-green">

                      Save {couponCodes.discount} with this code
                    </div>
                  </div>
                  <div className="flex flex-col px-8">
                    <div
                      className="cursor-pointer my-3 text-[#5E5E5E] flex items-center space-x-1"
                      onClick={() => toggleDetails(index)}
                    >
                      View Details
                      <img src={caratUp} className={`ml-2 ${isDetailsOpen === index ? "rotate-0" : "rotate-180"
                        }`} />
                    </div>
                    {isDetailsOpen === index && (
                      <div className="mt-2 ml-5">
                        <ul className="list-disc text-black">
                          <li className="mb-2">{couponCodes.description}.</li>
                       
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="my-3 border-t border-dashed border-stone-400 flex items-center justify-center">
                    <button className="text-green text-[20px] pt-2" onClick={() => handleApply(couponCodes.code)}>APPLY</button></div>
                </div>
              </div>
            ))}
          </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};
export default CouponPopUp;
