import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import im1 from '../../../src/assets/images/arrow1.png'
import placeholder from '../../../src/assets/images/placeholderImage.png';

type Dish = {
  name: string;
  images: string;
  id: string;
  url:string;
};

type AccordionSectionProps = {
  title: string;
  subtitle: string;
  dishes: Dish[];
  isOpen: boolean;
  toggleOpen: () => void;
};

const accordionData: { title: string; subtitle: string; dishes: Dish[] }[] = [
  {
    title: "Same Ingredients, New Dishes",
    subtitle: "Recipes you can make with the same ingredients",
    dishes: []
  }
];

const AccordionSection: React.FC<AccordionSectionProps> = ({ 
  title, 
  subtitle, 
  dishes, 
  isOpen, 
  toggleOpen 
}) => {
  const navigate = useNavigate();

  const handleView = (url: string) => {
    navigate(`/detail/${url}`, { replace: true });
  };

  return (
    <div className="w-full  -mb-12">
      <button
        className="w-full flex justify-between  items-center text-left px-2 sm:px-4  "
        onClick={toggleOpen}
      >
        <div>
          <h3 className="text-base sm:text-lg font-semibold text-black font-sans">{title}</h3>
          <p className="text-xs sm:text-sm text-[#525252] font-sans">{subtitle}</p>
        </div>
        <span className="transform transition-transform duration-200">
          {isOpen ? (
            <img src={im1} alt="up-arrow" className={`w-4 h-4 sm:w-6 sm:h-6 ${isOpen ? 'rotate-180' : ''}`} />
          ) : (
            '+'
          )}
        </span>
      </button>
      {isOpen && (
        <div className="overflow-x-auto">
          <div className="flex flex-nowrap gap-3 sm:gap-6 p-2 sm:p-4 min-w-min">
            {dishes?.map((dish, index) => (
              <button 
                key={index} 
                className="flex flex-col items-center flex-shrink-0 transition-transform duration-700 hover:scale-105 hover:translate-y-[-5px] hover:shadow-lg p-3 hover:rounded-lg"
                onClick={() => handleView(dish.url)}
              >
                <div className="relative w-16 h-16 sm:w-24 md:w-28 sm:h-24 md:h-28 overflow-hidden rounded-full ">
                  <img
                    src={dish.images || placeholder}
                    alt={dish.name}
                    className="w-full h-full object-cover absolute inset-0"
                  />
                </div>
                <p className="mt-1 sm:mt-2 font-sans text-black text-center whitespace-normal w-16 sm:w-24 md:w-28 text-xs sm:text-sm ">{dish.name}</p>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

interface SamePageProps {
  ingList: {
    data: Dish[];
  };
}

const App: React.FC<SamePageProps> = ({ ingList }) => {
  const [openSections, setOpenSections] = useState<boolean[]>(
    Array(accordionData.length).fill(true)
  );

  const toggleOpen = (index: number) => {
    setOpenSections((prev) => {
      const newOpenSections = [...prev];
      newOpenSections[index] = !newOpenSections[index];
      return newOpenSections;
    });
  };

  return (
    <div className="grid grid-cols-1 gap-4 sm:gap-8 mb-12">
      {accordionData.map((section, index) => (
        <AccordionSection
          key={index}
          title={section.title}
          subtitle={section.subtitle}
          dishes={ingList?.data}
          isOpen={openSections[index]}
          toggleOpen={() => toggleOpen(index)}
        />
      ))}
    </div>
  );
};

export default App;