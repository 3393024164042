import React, { useEffect, useState } from "react";
import offer from "../../../src/assets/images/offer.png";
import star from "../../../src/assets/images/star.png";
import { useAppDispatch } from "../../hooks";
import { getAdressByUserIdAsync } from "../../services/reducers/reciepeReducer";
import { ChevronDown } from "lucide-react";

const TopHeader = () => {
  const storedCode = localStorage.getItem("countryCode");
  const userId = localStorage.getItem("userId");
  const dispatch = useAppDispatch();
  const [cartAddress, setCartAddress] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isAddressOpen, setIsAddressOpen] = useState(false);
  const address = cartAddress.find(
    (addr: any) => addr.address_id === selectedAddress
  );

  // useEffect(() => {
  //   getAddress();
  // });

  const getAddress = async () => {
    if (userId) {
      const res = await dispatch(getAdressByUserIdAsync(userId));
      if (res.payload?.data) {
        setCartAddress(res.payload.data);
        if (!selectedAddress && res.payload.data.length > 0) {
          const country = storedCode === "IN" ? "India" : "United Kingdom";
          const addresses = res.payload.data.filter(
            (addr: any) => addr.country === country
          );
          if (addresses.length > 0) {
            handleAddressSelect(addresses[0].address_id);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (userId) {
      getAddress();
    }
  }, [userId]);

  const handleAddressSelect = (addressId: any) => {
    setSelectedAddress(addressId);
    setIsAddressOpen(false);
    // console.log("SELECTED address", addressId);
  };

  let offerText = "₹200 OFF on your First Order.";
  if (storedCode === "UK") {
    offerText = "£20 OFF on your First Order - WELCOME20";
  } else if (storedCode === "IN") {
    offerText = "₹200 OFF on your First Order - WELCOME200";
  }
  return (
    <>
      <div className="flex flex-wrap justify-center items-center bg-black text-white">
        <span className="mx-2 sm:mx-4">
          <img src={offer} alt="offer" className="w-6 sm:w-8 lg:w-6" />
        </span>
        <span className="px-2 font-sans text-center sm:text-left sm:px-4 text-sm sm:text-base lg:text-lg">
          {offerText}
        </span>
        <span className="mx-2 sm:mx-4">
          <img src={star} alt="star" className="w-4 sm:w-6 lg:w-6" />
        </span>
      </div>

      {/* address header */}
      {userId && (
      <div className="flex justify-end items-center bg-graybg text-black pr-8">
        <span className="">
          <svg
            className="w-3"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 11.25H14.25L16.5 16.5H1.5L3.75 11.25H6.75M9.75 6C9.75 6.19891 9.67098 6.38968 9.53033 6.53033C9.38968 6.67098 9.19891 6.75 9 6.75C8.80109 6.75 8.61032 6.67098 8.46967 6.53033C8.32902 6.38968 8.25 6.19891 8.25 6C8.25 5.80109 8.32902 5.61032 8.46967 5.46967C8.61032 5.32902 8.80109 5.25 9 5.25C9.19891 5.25 9.38968 5.32902 9.53033 5.46967C9.67098 5.61032 9.75 5.80109 9.75 6ZM4.5 6C4.5 9.75 9 13.5 9 13.5C9 13.5 13.5 9.75 13.5 6C13.5 3.43725 11.4855 1.5 9 1.5C6.5145 1.5 4.5 3.43725 4.5 6Z"
              stroke="#313131"
              stroke-width="2"
            />
          </svg>
        </span>

        {/* selector */}
        <div
          className="bg-graybg text-[15px] font-sans rounded-md"
          onClick={() => setIsAddressOpen(!isAddressOpen)}
        >
          {/* <ChevronDown size={16} className="ml-2 hidden sm:inline-block" /> */}
          <div className="px-2 py-none cursor-pointer bg-graybg">
            {selectedAddress
              ? `${address?.city}, ${address?.flat_no || ""} ${
                  address?.street_address || ""
                }`
              : "Select Address"}
          </div>
          {isAddressOpen && (
            <div className="absolute z-10 bg-white border rounded-md shadow-md mt-1 w-full">
              {cartAddress
                .filter((address: any) => {
                  const countryMappings: { [key: string]: string[] } = {
                    IN: ["IN", "India"],
                    UK: ["UK", "United Kingdom"],
                  };

                  if (storedCode) {
                    const normalizedSelectedCountry = Object.keys(
                      countryMappings
                    ).find((key) => countryMappings[key].includes(storedCode));

                    return (
                      normalizedSelectedCountry &&
                      countryMappings[normalizedSelectedCountry].includes(
                        address.country
                      )
                    );
                  }
                })
                .map((address: any, index: number) => (
                  <div
                    className="items-center py-2 px-2 hover:bg-pink truncate"
                    key={address.address_id}
                    onClick={() => handleAddressSelect(address.address_id)}
                  >
                    {address.city},{" "}
                    {address.flat_no ? `${address.flat_no} ` : ""}
                    {address.street_address ? `${address.street_address} ` : ""}
                    {/* {address.postal_code}, {address.country} */}
                  </div>
                ))}
            </div>
          )}
        </div>
        <ChevronDown
          size={14}
          className="hidden sm:inline-block"
          onClick={() => setIsAddressOpen(!isAddressOpen)}
        />
      </div>
      )}
    </>
  );
};

export default TopHeader;
