import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ChristmasSanta from "../../../src/assets/images/santa.png";
// import Christmas123 from "../../../src/assets/images/Bannar Big (3).png";
// import Christmas456 from "../../../src/assets/images/Group 1321315140 (2).png";
import ChristmasSanta from "../../../src/assets/images/valres.gif";
import Christmas123 from "../../../src/assets/images/5.png";
import Christmas456 from "../../../src/assets/images/val3.png";
import { useNavigate } from "react-router-dom";

const ChristmasResponsive: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };
  const navigate = useNavigate();
  return (
    <div className="w-full mt-1 block lg:hidden px-4"> 
      <Slider {...settings}>
        <div>

          <div className="w-full h-[250px] md:h-[320px] flex items-center justify-center" onClick={() => navigate('/valentine-special')}>

            <img
              src={ChristmasSanta}
              alt="Christmas Santa"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>
        <div>
          <div className="w-full h-[250px] md:h-[320px] flex items-center justify-center">
            <img

              src={Christmas123}
              alt="Christmas Scene 123"
              className="w-full h-full object-contain rounded-lg "
            />
          </div>
        </div>
        <div>
          <div className="w-full h-[250px] md:h-[320px] flex items-center justify-center" onClick={() => navigate('/shop')}>
            <img
              src={Christmas456}
              alt="Christmas Scene 456"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default ChristmasResponsive;
