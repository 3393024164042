import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import tick from "../../../assets/images/CancelTick.png";
import { useAppDispatch } from "../../../hooks";
import { getOrderByUserIdAsync } from "../../../services/reducers/reciepeReducer";
import placeholder from "../../../../src/assets/images/placeholderImage.png";

const ConfirmCancelPopup = () => {
  const navigate = useNavigate();
  const storedCode = localStorage.getItem("countryCode");
  const [getOrders, setGetOrders] = useState<any>([]);
  const userId = localStorage.getItem("userId");
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const handleConfirm = () => {
    // onConfirm();
    navigate("/profile");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (userId) {
      dispatch(getOrderByUserIdAsync(userId)).then((res) => {
        if (res.payload) {
          setGetOrders(res.payload?.data);
        }
      });
    }
  }, [userId]);

  return (
    // <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 px-4">
    <div className="bg-white p-10 items-center justify-center flex">
      <div className="bg-graybg flex p-12 rounded-lg flex-col items-center w-3/4">
        {/* header */}
        <div className="flex items-center justify-center flex-col mb-6 w-full">
          <img src={tick} alt="tick" />
          <span className="text-greytext font-medium text-lg">
            Order Cancelled
          </span>
        </div>
        {getOrders
          .filter((order: any) => order.id === parseInt(id ?? "", 10))
          .map((order: any) => (
            <div key={order.id} className="w-full">
              {/* items cancelled */}
              <div className="w-full text-left mb-4">
                <span className="text-black font-semibold">
                  {order?.items?.length} items cancelled
                </span>
                <div className="bg-white rounded-lg p-4 mt-2 shadow">
                  <span className="text-black">
                    Order Number:{" "}
                    <span className="text-black font-medium">{order?.id}</span>
                  </span>
                  <p className="text-black font-medium">
                    Total {order.currency === "INR" ? "₹" : "£"}
                    {order.order_total}
                  </p>
                </div>
              </div>
              {/* images */}
              <div className="bg-white rounded-lg p-4 mt-4 w-full flex gap-2 shadow">
                {order.items?.map((item: any) => (
                  <div
                    key={item.id}
                    className="snap-center flex-shrink-0 rounded-lg"
                  >
                    <img
                      src={item.info?.image || placeholder}
                      alt={`product-${item.id}`}
                      className="w-20 h-20 sm:w-24 sm:h-24 object-contain"
                    />
                  </div>
                ))}
              </div>
              {/* refund */}
              <div className="bg-white rounded-lg p-4 mt-4 w-full shadow">
                <span className="text-black font-semibold">Refund Details</span>
                <p className="text-greytext">
                  Refund of {order.currency === "INR" ? "₹" : "£"}
                  {order.order_total} is proceeded
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ConfirmCancelPopup;
