import React, { useState } from "react";
import im1 from "../../assets/images/cross.png";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedOption: any;
  setSelectedOption: any;
  getPersonaRecipeDetails: any;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  selectedOption,
  setSelectedOption,
  getPersonaRecipeDetails,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<"Cook Time" | "Cost">("Cook Time");
  // const [selectedOption, setSelectedOption] = useState<string | null>(null);

  // Define the cost options
  const costOptions = ["High to Low", "Low to High"];
  const categories = {
    "Cook Time": [
      "Less than 15 Mins",
      "Less than 30 Mins",
      "Less than 45 Mins",
      "Less than 60 Mins",
    ],
    Cost: ["High to Low", "Low to High"],
    // "Nutritional Category": [
    //   "Low on Calories",
    //   "High on Calories",
    //   "Low Carbohydrate",
    //   "High Carbohydrate",
    //   "High Protein",
    // ],
  } as const;
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center overflow-hidden">
      <div className="bg-white rounded-lg shadow-lg xl:w-[600px] md:w-1/3 w-3/4 lg:h-1/2">
        {" "}
        {/* Header Section */}
        <div className="flex justify-between items-center -mt-6 p-3 bg-[#FAFCFE] rounded-lg">
          <h2 className="text-xl font-bold font-sans text-black ml-4">Sort</h2>
          <button onClick={onClose}>
            <img src={im1} alt="Close" className="w-4 h-4 mr-2" />
          </button>
        </div>
        {/* Body Section */}
        <div className="flex h-[calc(100%-130px)]">
          <div className="w-1/3 border-r h-full mr-4 pt-4">
            <ul className="space-y-4 items-center">
              {Object.keys(categories).map((category) => (
                <li
                  key={category}
                  className={`cursor-pointer pl-4 font-sans pt-4 ${
                    selectedCategory === category
                      ? "border-l-4 border-red text-red font-bold rounded"
                      : "text-black"
                  }`}
                  onClick={() =>
                    setSelectedCategory(category as "Cook Time" | "Cost")
                  }
                >
                  {category}
                </li>
              ))}

              {/* <li
                key={selectedCategory}
                className={`flex items-center cursor-pointer p-2 ${selectedCategory ? "border-l-4 border-red text-red font-bold rounded"
                    : ""
                  }`}
                onClick={() => setSelectedCategory("Cost")}
              >
 
                <span className="text-black font-sans pl-2">{selectedCategory}</span>
              </li> */}
            </ul>
          </div>

          <div className="overflow-y-auto pt-4">
            <ul className="">
              {categories[selectedCategory].map((option) => (
                <li
                  key={option}
                  className={`flex items-center cursor-pointer p-2 `}
                  onClick={() => setSelectedOption(option)}
                >
                  <input
                    type="radio"
                    name="option"
                    className="mr-2 accent-green"
                    checked={selectedOption === option}
                    onChange={() => setSelectedOption(option)}
                  />
                  <span className={"text-black font-sans pl-2"}>{option}</span>
                </li>
              ))}
              {/* {costOptions.map((option) => (
                <li
                  key={option}
                  className={`flex items-center cursor-pointer p-2
                  }`}
                  onClick={() => setSelectedOption(option)}
                >
                  <input
                    type="radio"
                    name="cost-option"
                    className="mr-2 -ml-2 accent-green "
                    checked={selectedOption === option}
                    onChange={() => setSelectedOption(option)}
                  />
                  <span className="text-black font-sans pl-2">{option}</span>
                </li>
              ))} */}
            </ul>
          </div>
        </div>
        {/* Footer Section */}
        <div className="flex justify-end self-end mt-10 mr-4">
          <button
            className=" border-2 border-green text-green  hover:bg-green hover:text-white font-sans  rounded-md px-4 py-1 mr-2"
            onClick={() => setSelectedOption(null)}
          >
            Clear All
          </button>
          <button
            className="px-7 py-2 bg-green text-white rounded-md hover:bg-green-hover font-sans"
            onClick={() => {
              getPersonaRecipeDetails();
              onClose();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
