import { useNavigate } from "react-router-dom";
import BlogPost from "./blogPost";
import blog1 from "../../../src/assets/images/christmasBlog.png";
import { useAppDispatch } from "../../hooks";
import { useEffect, useState } from "react";
import { getBlogsAsync } from "../../services/reducers/reciepeReducer";
import moment from "moment";
import { count } from "console";
import placeholder from '../../../src/assets/images/placeholderImage.png';
import { truncateText } from "../../utils/common";

const BlogPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
   const [getBlogs, setGetBlogs] = useState<any>([]);
  const handleView = (title:string) => {
    navigate(`/blogPost/${title}`);
  };
  const storedCode = localStorage.getItem('countryCode');

useEffect(() => {
  const request = {
    page: 1,
    pageSize: 100,
    country: storedCode,
  };
    dispatch(getBlogsAsync(request)).then((res) => {
      if (res.payload) {
        setGetBlogs(res.payload?.data);
      }
    });
  }, []);
  
const convertDateWithMoment = (isoDate: string): string => {
    return moment(isoDate).format("MMMM D, YYYY");
  };
  const firstBlog = getBlogs[0];
  const remainingBlogs = getBlogs.slice(1);
  return (
    <div className="p-5 md:p-10 lg:p-10 overflow-y bg-graybg">
      <div className="blog text-3xl font-semibold mb-5 md:mb-10 lg:mb-10">
        Blog
      </div>
      <div className="shadow-[0_0px_10px_rgba(0,0,0,0.25)] w-full p-5 md:p-10 lg:p-10 rounded-lg text-left">
        <div className="flex flex-row justify-between">
          {/* column */}
          <div className="mr-none lg:mr-10">
          {firstBlog && firstBlog.post_date && (
              <span className="lg:text-sm md:text-sm text-xs text-[#172B4D]">
                Published on {convertDateWithMoment(firstBlog.post_date)} 
                {/* |{" "}
                {firstBlog.reading_time_minutes} min read */}
              </span>
            )}
            <br />
            <h3 className="text-md md:text-2xl lg:text-2xl text-black font-semibold my-5">
            {firstBlog && firstBlog?.title.replace(/!¬†¬†|¬†/g, '.')}
            </h3>
            <div className="flex lg:hidden justify-center w-full h-[180px] md:h-[280px] rounded-lg mb-5">
              <img
                src={""}
                alt={firstBlog?.title}
                className="w-full object-cover rounded-lg"
              />
            </div>
            <div className="text-sm md:text-lg lg:text-lg">
               {truncateText(firstBlog?.content)}
            </div>
            <button

              onClick={() => handleView(firstBlog.slug)}
              className="bg-[#0B6E27] text-white rounded-md font-medium text-[16px] sm:text-[14px] lg:text-[16px] text-[#0B6E27] px-2 mt-2 sm:px-8 lg:px-3 py-2 sm:py-2.5 lg:py-2 mt-8 lg:mt-8"

            >
              Read More
            </button>
          </div>

          {/* image */}
          <div>
            <div className="hidden lg:block flex justify-center w-[300px]">
              <img src={firstBlog && firstBlog.featured_image_url || placeholder} alt="" className="w-full h-full object-contain rounded-lg" />
            </div>
          </div>
        </div>
      </div>
      <BlogPost blogs={remainingBlogs}/>
    </div>
  );
};

export default BlogPage;
