import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { createByStripeAsync, createOrderAsync, deleteCartAsync } from '../../services/reducers/reciepeReducer';
import { useAppDispatch } from '../../hooks';
import im1 from "../../assets/images/cross.png";

interface PaymentFormValues {
  name: string;
}

const cardElementOptions: StripeCardElementOptions = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  cartTotal: { cart_value: number };
  price: number;
  cartIngredients: any;
  ipAddress: any;
  address: any;
  selectedAddress: any;
  detail: any;
  countryCode: any;
}

const CheckOut: React.FC<ModalProps> = ({ isOpen, onClose, cartTotal, price, cartIngredients, ipAddress, address, selectedAddress, detail, countryCode }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem('userId');

  const handleSubmit = async (values: PaymentFormValues) => {
    if (!stripe || !elements) {
      console.error('Stripe has not loaded yet.');
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      console.error('CardElement not found.');
      return;
    }
    if (selectedAddress) {
      const products = Object.keys(cartIngredients).flatMap((dishKey: string) => {
        const dishId = Number(dishKey.split('-')[1]);
        const ingredients = cartIngredients[dishKey];

        return ingredients.map((ingredient: any) => ({
          ingredient_id: ingredient.id,
          name: ingredient.name,
          recipe_id: dishId,
          quantity: ingredient.selected,
        }));
      });

      const requestBody = {
        order: {
          order_date: new Date().toISOString(),
          paid_date: new Date().toISOString(),
          phone: '',
          name: values.name,
          status: 'pending',
          currency: 'GBP',
          ip_address: ipAddress,
          user_agent: 'web',
          user_id: userId,
          shipping_address: address?.street_address,
          shipping_postcode: address?.postal_code,
          shipping_city: address?.city,
          shipping_state: '',
          shipping_country: address?.country,
          shipping_total: parseFloat(cartTotal?.cart_value.toFixed(2)),
          order_subtotal: parseFloat(cartTotal?.cart_value.toFixed(2)),
          order_total: parseFloat(cartTotal?.cart_value.toFixed(2)),
          discount_total: detail.discount,
          coupon_id: detail.id
        },
        products: products,
      };

      try {
        const createOrderResponse = await dispatch(createOrderAsync(JSON.stringify(requestBody))).unwrap();

        if (createOrderResponse.success && createOrderResponse.data && createOrderResponse.data.orderId) {
          const cartOrderId = createOrderResponse.data.orderId;
          // console.log('Order created successfully. Order ID:', cartOrderId);

          const paymentRequest = {
            currency: 'GBP',
            amount: price * 1000,
            orderId: cartOrderId,
          };

          const stripeResponse = await dispatch(createByStripeAsync(paymentRequest)).unwrap();

          if (stripeResponse.payload && stripeResponse.payload.clientSecret) {
            const { clientSecret } = stripeResponse.payload;

            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
              payment_method: {
                card: cardElement,
                billing_details: {
                  name: values.name,
                },
              },
            });

            if (error) {
              console.error('Payment error:', error.message);
            } else {
              // console.log('Payment successful!', paymentIntent);
              if (userId) {
              const reqBody = {
                country: countryCode
              };
              const deleteResponse = dispatch(deleteCartAsync({ id: userId, reqBody })).unwrap();
              window.location.reload();
            }
              onClose();
            }
          } else {
            console.error('Failed to retrieve client secret from response:', stripeResponse);
          }
        }
      } catch (error) {
        console.error('Error during order creation or payment:', error);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      ></div>

      {/* Modal Content */}
      <div className="bg-white rounded-lg shadow-lg p-8 z-10 w-full max-w-md mx-4 flex flex-col relative">
  <div className="flex justify-between items-center -mt-8  p-4 rounded-md mb-4 -ml-8 -mr-8 bg-[#FAFCFE]">
    <h2 className="text-xl font-semibold">Complete Your Payment</h2>
    <button
      className="self-end"
      onClick={onClose}
    >
      <img
        src={im1}
        alt="Close"
        className="w-4 h-4 -mt-6 "
      />
    </button>
  </div>

  <Formik
    initialValues={{ name: '' }}
    validationSchema={Yup.object({
      name: Yup.string().required('Name is required'),
    })}
    onSubmit={handleSubmit}
  >
    {({ errors, touched }) => (
      <Form className="flex flex-col">
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <Field
            id="name"
            name="name"
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
          {errors.name && touched.name && (
            <div className="text-red-500 text-sm mt-1">{errors.name}</div>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Card Details
          </label>
          <CardElement
            options={cardElementOptions}
            className="p-2 border border-gray-300 rounded-md"
          />
        </div>

        <button
          type="submit"
          disabled={!stripe}
          className="w-full bg-green text-white py-2 rounded-md hover:bg-green-hover transition"
        >
          Pay
        </button>
      </Form>
    )}
  </Formik>
</div>

    </div>
  );
};

export default CheckOut;
