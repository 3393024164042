import axios from "axios";
import { API_URL } from "../sharedService/constants";
import { http } from "../sharedService/httpService";
import { url } from "inspector";

// interface CreateCodRequest {
//   orderId: any;
// }

export function getRecipeByTime(reqBody: any) {
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}recipe/get-by-time?time=${reqBody?.time}&country=${reqBody?.country}`
  );
}

export function getFestiveDishes(reqBody: any) {
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}festive/dishes?country=${reqBody.country}`
  );
}


export function getRecipeIngredient(reqBody: any) {
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}recipe/get-rec/${reqBody?.id}?country=${reqBody?.country}`
  );
}

export function getRecipeById(reqBody: any) {
  return axios.get(`${API_URL.INTEGRATION_API_URL}recipe/getByUrl?url=${reqBody.url}&country=${reqBody?.country}`);
}

export function createByStripe(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}pay/create-stripe-order`,
    reqBody
  );
}

export function createByRazorpay(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}pay/create-razorpay-order`,
    reqBody
  );
}

export function getSearchAll(reqBody: any) {
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}shop/searchAll?term=${reqBody?.term}&country=${reqBody?.country}`
  );
}

export function getCatCousine(reqBody: any) {
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}shop/cat?cuisine=${reqBody?.cuisine}&country=${reqBody?.country}`
  );
}

export function getSubCatByCousineCat(reqBody: any) {
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}shop/sub-cat?cuisine=${reqBody?.cuisine}&country=${reqBody?.country}&category=${reqBody?.category}`
  );
}

export function addUpdateItem(id: string, reqBody: any) {
  return axios.post(`${API_URL.INTEGRATION_API_URL}cart/${id}`, reqBody);
}

export function getItemsById(id: string, country: string) {
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}cart/${id}?country=${country}`
  );
}

export function getVendorCategories(name : string){
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}vendor/categories/${name}`
  );
}

export function getVendorSubCategories(name : string,category:string){
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}vendor/sub/${name}?category=${category}`
  );
}

export function createOrder(reqBody: any) {
  return http.post(`${API_URL.INTEGRATION_API_URL}order/create-order`, reqBody);
}

export function getAdressByUserId(id: string) {
  return axios.get(`${API_URL.INTEGRATION_API_URL}user/fetchAll/${id}`);
}

export function addAddress(reqBody: any) {
  return axios.post(`${API_URL.INTEGRATION_API_URL}user/add-address`, reqBody);
}

export function getOrderByUserId(id: string) {
  return http.get(`${API_URL.INTEGRATION_API_URL}order/user/${id}`);
}

// export function getByPersona(reqBody: any) {
//   return http.post(
//     `${API_URL.INTEGRATION_API_URL}recipe/get-by-persona?persona=${reqBody?.persona}&country=${reqBody?.country}&veg=${reqBody?.veg}&sort=${reqBody?.sort}`, reqBody
//   );
// }

export function getByPersona(reqBody: {
  persona: string;
  country: string;
  veg: boolean;
  sort?: string;
  cuisines?: string[];
}) {
  let nevUrl = `recipe/get-by-persona?persona=${reqBody.persona}&country=${reqBody.country}&veg=${reqBody.veg}`;
  if (reqBody.sort && reqBody.sort !== "") {
    nevUrl += `&sort=${reqBody.sort}`;
  }
  if (reqBody.cuisines && reqBody.cuisines.length > 0) {
    nevUrl += `&cuisines=[${reqBody.cuisines.join(",")}]`;
  }
  //console.log("Constructed URL:", `${API_URL.INTEGRATION_API_URL}${nevUrl}`);
  return axios.post(`${API_URL.INTEGRATION_API_URL}${nevUrl}`);
}

export function getCategoryByCouisine(id: string) {
  return http.get(`${API_URL.INTEGRATION_API_URL}order/user/${id}`);
}

export function getProducts(country: string, reqBody: any) {
  return axios.post(
    `${API_URL.INTEGRATION_API_URL}shop/products?country=${country}`,
    reqBody
  );
}

export function getVendorProducts(url: string, reqBody: any) {
  return axios.post(
    `${API_URL.INTEGRATION_API_URL}vendor/products/${url}`,
    reqBody
  );
}

export function getSingleProduct(reqBody: any) {
  return axios.get(`${API_URL.INTEGRATION_API_URL}shop/getByURL?url=${reqBody.url}&country=${reqBody?.country}`);
}

export function getSubscriptionInfo(id: string) {
  return http.get(`${API_URL.INTEGRATION_API_URL}user/sub-info/${id}`);
}

export function subScribeBasic(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}user/subscribe-basic`,
    reqBody
  );
}

export function createPlan(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}pay/create-subscription-order`,
    reqBody
  );
}

export function createPlanRozarpay(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}pay/create-subscription-razorpay`,
    reqBody
  );
}

export function createPlanStripe(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}pay/create-subscription-order`,
    reqBody
  );
}
export function cancelByStripe(reqBody: any) {
  return http.post(`${API_URL.INTEGRATION_API_URL}pay/cancel-stripe`, reqBody);
}

export function cancelByRozarpay(reqBody: any) {
  return http.post(
    `${API_URL.INTEGRATION_API_URL}pay/cancel-razorpay`,
    reqBody
  );
}

export function cancelByCOD(reqBody: any) {
  return http.post(`${API_URL.INTEGRATION_API_URL}pay/cancel-cod`, reqBody);
}

export function getAllCoupon(country: string) {
  return http.get(`${API_URL.INTEGRATION_API_URL}coupon/all?country=${country}`);
}

export function redeemCoupon(reqBody: any) {
  return http.post(`${API_URL.INTEGRATION_API_URL}coupon/redeem`, reqBody);
}

export function updateAddress(id: string, reqBody: any) {
  return http.put(
    `${API_URL.INTEGRATION_API_URL}user/update-address/${id}`,
    reqBody
  );
}

export function deleteAddress(Id: string) {
  return http.put(`${API_URL.INTEGRATION_API_URL}user/delete-address/${Id}`,
    );
}

export function deleteCart(Id: string, reqBody: any) {
  return http.delete(`${API_URL.INTEGRATION_API_URL}cart/${Id}/clear?country=${reqBody.country}`,
    reqBody);
}

export function getExoticList(country: string) {
  return axios.get(`${API_URL.INTEGRATION_API_URL}shop/exotic?country=${country}`);
}
export function getBlogs(reqBody: any) {
  return axios.get(`${API_URL.INTEGRATION_API_URL}blog/getAllBlog?page=${reqBody?.page}&pageSize=${reqBody?.pageSize}&country=${reqBody?.country}`);
}

export function getBlogById(title: string) {
  return axios.get(`${API_URL.INTEGRATION_API_URL}blog/getBlogById/${title}`,
    );
}

export function createComment(reqBody: any) {
  return axios.post(`${API_URL.INTEGRATION_API_URL}blog/comments`, reqBody
    );
}

export function replyComment(reqBody: any) {
  return axios.post(`${API_URL.INTEGRATION_API_URL}blog/replies`, reqBody
    );
}

export function getCommentById(id: string) {
  return axios.get(`${API_URL.INTEGRATION_API_URL}blog/comments/${id}`,
    );
}

export function getVendor(url: string) {
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}vendor/info/${url}`,
  );
}

export function createLikeUnlike(reqBody: any) {
  return axios.post(`${API_URL.INTEGRATION_API_URL}blog/like/toggle`, reqBody
    );
}

export function getLikeUnlike(id: string) {
  return axios.get(`${API_URL.INTEGRATION_API_URL}blog/like/${id}`,
    );
}


export function addTransferCart(country: string, reqBody: any) {
  return axios.post(`${API_URL.INTEGRATION_API_URL}cart/transfer?country=${country}`, reqBody);
}

export function getViewCount() {
  return axios.get(`${API_URL.INTEGRATION_API_URL}blog/viewCount`,
    );
}

export function getRecipeByCuisine(reqBody: any) {
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}recipe/get-by-cuisine?cuisine=${reqBody.cuisine}&country=${reqBody.country}`,
  );
}


export function getUserPref(id: string) {
  return axios.get(`${API_URL.INTEGRATION_API_URL}user/get-pref/${id}`,
    );
}

export function updateUserPref(reqBody: any) {
  return axios.post(`${API_URL.INTEGRATION_API_URL}auth/update-pref`, reqBody
    );
}

export function getAllTag(tag: any) {
  return axios.get(
    `${API_URL.INTEGRATION_API_URL}blog/getByTag?tag=${tag}`,
  );
}

