import { useEffect, useState } from "react";
import banner1 from "../../../src/assets/images/BG.png";
import banner from "../../../src/assets/images/banner.webp";
import banner2 from "../../../src/assets/images/group.webp";
import Typography from "../Typography";
import arrow from "../../../src/assets/images/arrow.png";
import { Link } from "react-router-dom";
import uk from "../../../src/assets/images/BgLeft.png";

const Banner = () => {
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    const storedCountry = localStorage.getItem("selectedCountry");
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  }, [selectedCountry]);

  const bannerToDisplay =
    selectedCountry === "India"
      ? banner
      : selectedCountry === "UK"
        ? banner2
        : null;
        
  return (
    <div className="grid grid-cols-1 h-full mb-8 p-4 lg:p-12 lg:grid-cols-12 ">
      <div className="col-span-8 relative h-full mr-0 lg:mr-8 lg:col-span-8">
        {bannerToDisplay && (
          <img
            src={bannerToDisplay}
            alt="Main Banner"
            srcSet="w-full h-auto sm:w-full"
            // className="w-full h-auto"
            loading="eager"
          />
        )}
     <div className="absolute inset-0 flex flex-col justify-center items-start lg:p:12">
  {/* <Typography
    variant="h1"
    className="font-bold 
      lg:text-3xl 
      md:text-2xl 
      text-sm 
      xs:text-xs  // Added for extra small screens
      pb-8 
      font-sans"
  >
    Are you Bored of{" "}
    <span className="text-[#FF0000] font-sans">Bland Recipe Kit</span> */}
    <div className="font-sans font-bold xl:text-3xl lg:text-2xl md:text-2xl text-sm [text-wrap:balance] bg-clip-text text-black from-slate-200/60 to-50% to-slate-200">Are you Bored of {' '}
    <span className="text-[#FF0000] inline-flex flex-col xl:h-[calc(theme(fontSize.3xl)*theme(lineHeight.loose))] lg:h-[calc(theme(fontSize.3xl)*theme(lineHeight.loose))] md:h-[calc(theme(fontSize.4xl)*theme(lineHeight.tight))] h-[calc(theme(fontSize.lg)*theme(lineHeight.loose))] overflow-hidden">
    <ul className="block animate-text-slide-5 text-left leading-loose [&_li]:block">
        <li>Bland Recipe Kits</li>
        <li>Expensive Kits</li>
        <li>Limited Choice Kits</li>
        <li>Unsustainable Kits</li>
        <li>Standard Recipe Kits</li>
        <li aria-hidden="true">Bland Recipe Kits</li>
    </ul>
</span></div>
    {/* <br />
    Try AnyFeast{" "}
    <span className="text-green font-sans">
      Authentic and tasty
    </span> */}
     <div className="font-sans font-bold xl:text-3xl lg:text-2xl md:text-2xl text-sm [text-wrap:balance] bg-clip-text text-black from-slate-200/60 to-50% to-slate-200">Try AnyFeast {' '}
     <span className="text-green inline-flex flex-col xl:h-[calc(theme(fontSize.3xl)*theme(lineHeight.loose))] lg:h-[calc(theme(fontSize.3xl)*theme(lineHeight.loose))] md:h-[calc(theme(fontSize.4xl)*theme(lineHeight.tight))] h-[calc(theme(fontSize.lg)*theme(lineHeight.loose))] overflow-hidden">
     <ul className="block animate-text-slide-5 text-left leading-loose [&_li]:block">
        <li>Authentic and Tasty</li>
        <li>Affordable Kits</li>
        <li>Extensive Choice Kits</li>
        <li>Eco-Friendly Kits</li>
        <li>Powered by AI</li>
        <li aria-hidden="true">Authentic and Tasty</li>
    </ul>
</span></div>
  {/* </Typography> */}

          <div className="flex items-center mt-1 lg:mt-4 mb-5 lg:mb-0">
            <div className="
      !h-16 
      !w-[0.1675rem] 
      md:!h-20 
      lg:!h-20 
      !rounded-lg 
      !left-0 
      !top-3/4 
      lg:!top-1/2 
      -translate-y-1 
      md:-translate-y-5 
      lg:-translate-y-6  
      bg-[#FF8A00] 
      font-sans"
            ></div>

            <Typography
              variant="h4"
              className="
        font-medium 
        lg:font-bold 
        text-xs 
        md:text-xl 
        xs:text-2xs  // Added for extra small screens
        pb-2 
        md:pb-12 
        pl-2 
        lg:pl-4 
        md:pl-3 
        font-sans"
            >
              Sale up to{" "}
              <span className="
        bg-[#FF8A00] 
        p-1 
        lg:p-2 
        text-xs 
        md:text-sm 
        xs:text-2xs  // Added for extra small screens
        lg:text-sm 
        rounded-md 
        font-sans"
              >
                30% OFF
              </span>
              <br />
              <span className="
        mt-3 
        lg:mt-6 
        block 
        font-sans 
        xs:text-2xs  // Added for extra small screens
      ">
                Free shipping on all your orders.
              </span>
            </Typography>
          </div>

          <Link to="/persona/:id">
            <button className="
      bg-white 
      text-black 
      border 
      border-slate-300 
      hover:border-[#FF0000] 
      px-5 
      lg:px-12 
      py-2 
      lg:py-4 
      rounded-lg 
      shadow-lg 
      hover:shadow-2xl 
      transition-shadow 
      duration-300 
      font-medium 
      lg:font-semibold 
      lg:text-lg 
      md:text-lg 
      text-sm 
      xs:text-2xs  // Added for extra small screens
    ">
              Explore Recipes
            </button>
          </Link>
        </div>
      </div>

      <div className="hidden lg:block col-span-4 h-full bg-white">
        <div className="flex flex-col justify-between h-full flex-grow">
          <div
            className="h-full flex-grow w-full mb-8 rounded-lg"
            style={{
              background: `url(${banner1})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* <img src={banner1} alt="Banner 1" className="w-full h-auto" /> */}
            <div className="flex flex-col justify-start items-start">
              <div className="p-10">
                <Typography variant="h4" className="text-md font-sans">
                  WINTER SALE
                </Typography>
                <Typography
                  variant="h1"
                  className="font-bold text-2xl pb-4 font-sans"
                >
                  35% OFF
                </Typography>
                <Typography variant="h6" className="pb-4 font-sans">
                  Exotic Fruit & Vegetable
                </Typography>
                <div className="flex items-center">
                  <Link to="/shop">
                    <Typography
                      variant="h6"
                      className="text-[#00B207] font-sans"
                    >
                      Shop Now
                    </Typography>
                  </Link>
                  <span className="pl-2">
                    <Link to="/shop">
                      <img src={arrow} alt="arrow"></img>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="h-full flex-grow w-full"
            style={{
              background: `url(${uk})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* <img src={banner2} alt="Banner 2" className="w-full h-auto" /> */}
            <div className="flex flex-col justify-between items-center">
              <div className="">
                <Typography
                  variant="h4"
                  className="text-md pt-20 pl-32 font-sans"
                >
                  BEST DEAL
                </Typography>
              </div>
              <div className="pl-28 pt-2">
                <Typography
                  variant="h1"
                  className="font-bold text-center text-xl font-sans"
                >
                  Authentic Meal Kit Deal of <br></br>the Month
                </Typography>
              </div>
              <div className="flex items-center pl-28 pt-8 font-sans">
                <Link to="/shop"><Typography variant="h6" className="text-[#00B207]">
                  Shop Now
                </Typography></Link>
                <span className="pl-2">
                  <Link to="/shop">
                    <img src={arrow} alt="arrow"></img>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;