import { useState, useEffect } from 'react'
import Banner from './Banner'
import FeatureSection from './FeatureSection'
import ExploreRecipe from './ExploreRecipe'
import CategoriesSection from './Categories'
import DelightReceipe from './DelightReceipe'
import HowItWorksSection from './HowToWork'
import ReceipeKit from './ReceipeKit'
import SaleSection from './Sale'
import CuisineSlider from './SwiperSlider'
import FAQ from './FAQ'
import GallerySection from "./SameDishes";
import TestimonialSlider from "./Slider";
import DiwaliHeader from './DiwaliHeader'
import ChristmasBanner from './ChristmasBanner'
import ChristmasBanner2 from './ChristmasBanner2'
import ChristmasResponsive from './ChristmasResponsive'
import CuisineSliderTrial from '../Shop/CuisineSliderTrial'
import DownloadSection from './Download'
 
const HomePage = () => {
  const [selectedCountry, setSelectedCountry] = useState('India');
  const [countryCode, setCountryCode] = useState<string | null>('IN');
  const [isFestival, setIsFestival] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1023);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 
  useEffect(() => {
    const storedCountry = localStorage.getItem('selectedCountry');
    const storedCode = localStorage.getItem('countryCode');
    if (storedCountry) {
      setSelectedCountry(storedCountry);
      setCountryCode(storedCode);
    }
  }, []);
  return (
    <div>
      {isFestival ? (
        isMobile ? (
          <ChristmasResponsive />
        ) : (
          <> 
            {/* <ChristmasBanner /> */}
           <div className="h-4" />
            <ChristmasBanner2 />
          </>
        )
      ) : ( 
        <Banner />
         )}   
      <DownloadSection/>
      <FeatureSection />
      <CuisineSlider />
      <ExploreRecipe />
      <CuisineSliderTrial />
      {/* <CategoriesSection /> */}
      <HowItWorksSection />
      <DelightReceipe />
      {/* <ReceipeKit/> */}
      {/* <GallerySection/> */}
      {/* <SaleSection /> */}
      
      <TestimonialSlider />
      <FAQ />
 
    </div>
  )
}
 
export default HomePage
 
 