import React, { FC, useState } from "react";
import logo from "../../../src/assets/images/logo.png";
import google from "../../../src/assets/images/google.png";
import OTPForm from "./OtpModal";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styled from "styled-components";
import { emailConfig, sendOTP } from "../../services/sharedService/AuthService";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import im1 from "../../assets/images/cross.png";

export const InputStyle = styled.div`
  .PhoneInputInput {
    font-size: 0.938rem !important;
    font-weight: 400 !important;
    color: #232323 !important;
    background: transparent;
  }
  .PhoneInputInput:focus-visible {
    box-shadow: none !important;
    outline: none;
  }
`;

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setGetPhone?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, setIsModalOpen, setGetPhone }) => {
  const [currentModal, setCurrentModal] = useState<"main" | "otp">("main");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const isContinueButtonDisabled = phone.length < 13 || phoneError !== null;
  const hostname = window.location.hostname;
  
  const handleContinueClick = () => {
    setCurrentModal("otp");
    sendOTP(phone, "web")
      .then(() => {
        toast.success("OTP sent successfully.");
        setCurrentModal("otp");
      })
  };
  const handleOTPModalClose = () => {
    setCurrentModal("main");
    // setAllergyPopUp(true);
  };
  const handlePhoneChange = (value: string | undefined) => {
    if (value && value.length > 13) {
      setPhoneError("Phone number cannot exceed 10 digits.");
      setPhone(value.substring(0, 13));
      return;
    }
    setPhone(value || "");
    setPhoneError(null);
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const { access_token } = response;
        const userInfoResponse = await axios.get("https://www.googleapis.com/oauth2/v2/userinfo", {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });

        const { email, name } = userInfoResponse.data;
        localStorage.setItem("userName", name);
        localStorage.setItem("userEmail", email);
        const emailResponse = await emailConfig(email, name);
        const token = emailResponse.data.token;
        const phone = emailResponse.data.phone;
        const id = emailResponse.data.id;
        localStorage.setItem('accessToken', token);
        localStorage.setItem('userId', id);
        localStorage.setItem('userPhone', phone);
        
        // console.log("Email Config Success:", emailResponse);

        if(emailResponse){
          setIsModalOpen(false);
        }

      } catch (error) {
        console.error("Login Failed:", error);
      }
    },
    onError: () => {
      console.error("Google Login Failed");
    },
  });

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      {currentModal === "main" && (
        <div className="bg-white rounded-[20px] shadow-lg w-full sm:w-[750px] h-auto max-h-[90vh] p-5 sm:p-10 border border-[rgba(0,0,0,0.6)]">
          <div className="text-center">
            <div className="grid grid-cols-3 gap-8">
              <button onClick={onClose}>
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1_973)">
                    <path
                      d="M27.5 16H5.5"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.5 7L5.5 16L14.5 25"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_973">
                      <rect
                        width="32"
                        height="32"
                        fill="white"
                        transform="matrix(0 1 -1 0 32.5 0)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <img src={logo} alt="Logo" className="mx-auto mb-4 md:h-12" />
            </div>
            <h2 className="text-xl font-semibold mb-4">
              World's #1 Customisable Meal Kit Delivery App
            </h2>
            <div className="flex items-center m-6">
              <div className="flex-grow border-t border-gray-300"></div>
              <p className="px-4">Log in or Sign Up</p>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
            <div className="flex mb-6 justify-center items-start gap-2">
              <div className="w-full sm:w-[48%]">
                <InputStyle>
                  <PhoneInput
                    className="w-full outline-none bg-none border-2 !border-gray-300 !border-opacity-50 rounded-lg p-3 px-4 text-450 text-black placeholder:text-gray-300 font-normal disabled:bg-gray-200"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry={hostname.endsWith('.in') ? "IN" : "GB"}
                    countries={["IN", "GB"]}
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="enter phone number"
                  />
                  {phoneError && (
                    <div className="text-red text-200 mt-1 font-sans">{phoneError}</div>
                  )}
                </InputStyle>
              </div>
            </div>
            <button
              className={`w-[50%] sm:w-[40%] p-4 rounded-lg mb-4 ${isContinueButtonDisabled ? 'bg-[#A6A6A6] text-black' : 'bg-black text-white'}`}
              onClick={handleContinueClick}
              disabled={isContinueButtonDisabled}
            >
              Continue
            </button>
            <div className="flex items-center mt-4 mb-8">
              <div className="flex-grow border-t border-gray-300"></div>
              <h1 className="text-black text-lg px-4">or</h1>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
            <div className="flex justify-around">
              <button className="flex items-center border border-gray-300 rounded-lg px-14 py-4" onClick={() => login()}>
                <img src={google} alt="Google" className="h-6 mr-2" />  
                Continue with Google
              </button>
              {/* <button className="flex items-center border border-gray-300 rounded-lg px-14 py-4">
                <img src={facebook} alt="Facebook" className="h-6 mr-2" />
                Continue with Facebook
              </button> */}            </div>
          </div>
        </div>
      )}

      {currentModal === "otp" && (

        <OTPForm type = "login" onClose={handleOTPModalClose} isOpen={isOpen} phone={phone} setIsModalOpen={setIsModalOpen} handlePhoneChange={handlePhoneChange} setGetPhone={setGetPhone}/>

      )}
        {/* {allergyPopUp && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 xl:w-[600px] lg:w-1/3 md:w-1/3 w-3/4 h-1/2">
          <div className="flex justify-between items-center -ml-6 -mr-6 -mt-6 p-3 bg-[#FAFCFE] rounded-lg">
          <h2 className="text-xl font-semibold font-sans ml-2 text-black">
            Cuisines
          </h2>
          <button onClick={onClose}>
    <img
      src={im1}
      alt="Close"
      className="w-4 h-4 mr-2"
    />
  </button>
        </div>
              <div className="grid gap-4 grid-cols-2 sm:grid-cols-3 sm:flex sm:flex-wrap sm:gap-4 sm:justify-start mt-4">
                {allergiesOptions.flat().map((allergy) => (
                  <button
                    key={allergy}
                    onClick={() => toggleAllergy(allergy)}
                    className={`w-full h-12 sm:w-auto px-6 py-2 rounded-md border-2 text-sm font-medium transition-colors
                      ${
                        selectedAllergies.includes(allergy)
                          ? 'bg-red-100 text-green border-green'
                          : 'bg-white text-black border-[#EEEEEE] hover:border-[rgb(255,243,243)]'
                      }`}
                  >
                    {allergy}
                  </button>
                ))}
              </div>

              <div className="flex justify-end self-end mt-2">
          <button
            className=" border-2 border-green text-green  hover:bg-green hover:text-white font-sans  rounded-md px-4 py-1 mr-2"
            onClick={() => {}}
          >
            Skip for now
          </button>
          <button
            className="px-7 py-2 bg-green text-white rounded-md hover:bg-green-hover font-sans"
            onClick={() => {}}
          >
            Next
          </button>
        </div>
            </div>
        </div>
      )} */}
    </div>
  );
};

export default Modal;