import React, { FC, useCallback, useState } from "react";
import { verifyOtp } from "../../services/sharedService/AuthService";
import OtpInput from "react-otp-input";
import OTPTimer from "./OTPTimer";
import styles from "./OtpForm.module.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { addTransferCartAsync, getAdressByUserIdAsync } from "../../services/reducers/reciepeReducer";

interface ModalProps {
  type: string;
  cartID?:string;
  setOtpSuccess?: React.Dispatch<React.SetStateAction<boolean>>; 
  isOpen: boolean;
  onClose: () => void;
  phone: string;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handlePhoneChange: (phone: string) => void;
  setGetPhone?:React.Dispatch<React.SetStateAction<boolean>>; 
}

const OTPForm: FC<ModalProps> = ({type, cartID, setOtpSuccess, isOpen, onClose, phone, setIsModalOpen, handlePhoneChange, setGetPhone }) => {
  const [otp, setOtp] = useState("");
  const [otpTimerExpired, setOTPTimerExpired] = useState(false);
  const [isOtpCorrect, setIsOtpCorrect] = useState<null | boolean>(null);
  const [shake, setShake] = useState(false); 
  const navigate = useNavigate();
  const guestId = localStorage.getItem("guestId");
  const userId = localStorage.getItem("userId");
  const storedCode = localStorage.getItem("countryCode");
    const dispatch = useAppDispatch();
    
  const handleTimerExpired = () => {
    setOTPTimerExpired(true);
  };

  const handleConfirm = (id: string) => {
    navigate(`/OrderConfirm/${id}`);
    window.scrollTo(0, 0);
  };

  const handleVerifyOTP = useCallback(async (otp: string) => {
    if (otp.length !== 6 || !phone) return;

    try {
      const response = await verifyOtp(phone, otp);
      const { token, id } = response.data;

      localStorage.setItem("accessToken", token);
      localStorage.setItem("userId", id);
      localStorage.setItem("userPhone", phone);

      setIsOtpCorrect(true);

      if (setOtpSuccess) {
        setOtpSuccess(true);
      }
     
      setTimeout(() => {
        setIsModalOpen(false);
        window.location.reload();
        onClose?.();
        setGetPhone?.(true);
        if (storedCode) {
          dispatch(addTransferCartAsync({ country: storedCode, request: { sid: guestId, uid: id } }));
        }

        localStorage.removeItem("guestId");
  
        
      }, 1000);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setIsOtpCorrect(false);
      setShake(true);
      setTimeout(() => setShake(false), 500);
    }
  }, [phone, storedCode]);
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
  <div className="bg-white rounded-lg w-11/12 max-w-[40rem] relative">
    <div className="shadow-md transition-shadow duration-300 border-t-2 bg-white p-6 rounded-t-lg flex justify-center items-center relative">
      <button className="absolute left-4 text-xl" onClick={onClose}>
        <svg
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1_973)">
            <path
              d="M27.5 16H5.5"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 7L5.5 16L14.5 25"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_973">
              <rect
                width="32"
                height="32"
                fill="white"
                transform="matrix(0 1 -1 0 32.5 0)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
      <h2 className="text-lg font-semibold text-center">OTP</h2>
    </div>
    <div className="text-center pt-6 pb-12">
      <p className="text-gray-500 mt-2 font-sans">
        Enter 6 digit OTP sent on{" "}
      </p>
      <span className="font-bold font-sans">{phone}</span>
      
      {isOtpCorrect && type =="login" && (
        <p className="text-green-600 font-semibold mt-4">Login Successful!</p>
      )}
      {isOtpCorrect && type =="cart"  && (
        <>
        <p className="text-green-600 font-semibold mt-4">Order Successful!</p>
       {/* { setOtpSuccess(true)} */}
        </>
      )}
      {
        type == "cart" && cartID &&(
          <>
            {handleConfirm(cartID)}
          </>
        )
      }
      
      <div className={`flex justify-center mt-4 ${shake ? styles['animate-shake'] : ""}`}>
        <OtpInput
          value={otp}
          numInputs={6}
          containerStyle="flex space-x-2"
          onChange={(otp) => {
            setOtp(otp);
            handleVerifyOTP(otp);
          }}
          renderInput={(props: any) => (
            <input
              {...props}
              type="tel"
              inputMode="numeric" 
              autoComplete="one-time-code" 
              className={`border text-center rounded-md !w-12 !h-12 text-lg 
                ${isOtpCorrect === false ? "border-red-500 bg-red-100" : ""}
                ${isOtpCorrect === true ? "border-green-500 bg-green-100" : "border-gray-300"}`}
            />
          )}
        />
      </div>
      
      {otpTimerExpired && (
        <button className="mt-4 text-gray-500 font-sans text-sm" onClick={() => {
          setOTPTimerExpired(false);
          handlePhoneChange(phone);
        }}>
          Resend Code
        </button>
      )}
      {!otpTimerExpired && (
        <div className="text-gray-500 text-200 font-semibold mt-3 flex justify-center font-sans">
          OTP will be sent in{" "}
          <span className="ms-2 text-black">
            <OTPTimer expiryTime={60} onTimerExpired={handleTimerExpired} />
          </span>{" "}
        </div>
      )}
    </div>
  </div>
</div>

  );
};

export default OTPForm;
