import React, { useState, useRef, useEffect } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import { useAppDispatch } from '../../hooks';
import { addUpdateItemAsync, getCatCousineAsync, getItemsByIdAsync, getProductsAsync, getSubCatByCousineCatAsync, getVendorAsync, getVendorCatAsync, getVendorProductsAsync, getVendorSubCatAsync } from '../../services/reducers/reciepeReducer';
import { useNavigate, useParams } from 'react-router-dom';
import LoginModal from '../Home/LoginModal';
import { capitalizeWords } from '../../utils/common';
import placeholder from '../../../src/assets/images/placeholderImage.png';

const VendorExplore: React.FC = () => {

	const dispatch = useAppDispatch();
	const { url } = useParams();
	const storedCode = localStorage.getItem('countryCode');
	const [categoriesDetail, setCategoriesDetail] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState<string | undefined>();
	const [subCategory, setSubCategory] = useState([]);
	const [selectedSubcategory, setSelectedSubcategory] = useState<string | undefined>();
	const [products, setProducts] = useState([]);
	const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
	const navigate = useNavigate();
	const [isSmallScreen, setIsSmallScreen] = useState(false);
	const categorySliderRef = useRef<HTMLDivElement>(null);
	const productGridRef = useRef<HTMLDivElement>(null);
	const userId = localStorage.getItem("userId");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [vendorDetails, setVendorDetails] = useState<any>(null);

	const [vendorCountry , SetVendorCountry] = useState(null);
	const guestId = localStorage.getItem("guestId");

	useEffect(() => {


		try {
			dispatch(getVendorAsync(url!)).then((res: any) => {

				if (res.payload && res.payload.data) {
					setVendorDetails(res.payload.data);

					getCategory()
				}
			})

		} catch (error) {
			// console.log('Failed to fetch vendor details');
		}
	}, []);

	useEffect(() => {
		const updateScreenSize = () => {
			setIsSmallScreen(window.innerWidth < 768);
		};
		updateScreenSize();
		window.addEventListener('resize', updateScreenSize);
		return () => window.removeEventListener('resize', updateScreenSize);
	}, []);


	const scroll = (ref: React.RefObject<HTMLDivElement>, direction: 'left' | 'right') => {
		if (ref.current) {
			const scrollAmount = 200;
			const newScrollLeft = direction === 'left'
				? ref.current.scrollLeft - scrollAmount
				: ref.current.scrollLeft + scrollAmount;
			ref.current.scrollTo({
				left: newScrollLeft,
				behavior: 'smooth'
			});
		}
	};

	const getCategory = () => {
		setProducts([]);
		if (url) {
			dispatch(getVendorCatAsync({ name: url })).then((res: any) => {
				if (res.payload && res.payload.data && res.payload.data.length > 0) {
					setSelectedCategory(res.payload.data[0].category);
					setCategoriesDetail(res.payload.data);
					getSubCategory(res.payload.data[0].category)
				}
			});
		}
	};

	const getSubCategory = (cat: string | undefined) => {
		//const country = storedCode;
		setProducts([]);
		dispatch(getVendorSubCatAsync({ name: url!, category: cat != null ? cat : selectedCategory! })).then((res: any) => {
			if (res.payload) {
				setSubCategory(res.payload.data);

				if (res.payload.data && res.payload.data.length > 0) {
					setSelectedSubcategory(res.payload.data[0].sub_category);

					getProducts(res.payload.data[0].sub_category, cat);
				}
			}
		});

	};

	const getProducts = (sub: string | undefined, cat: string | undefined) => {
		if (selectedSubcategory !== "undefined") {
			const request = {
				sub: sub != null ? sub : selectedSubcategory,
				category: cat != null ? cat : selectedCategory
			};
			dispatch(getVendorProductsAsync({ name: url!, request })).then((res: any) => {
				if (res.payload && res.payload.data) {
					setProducts(res.payload.data.products);
				}
			});
		}
	};


	const handleCategoryChange = (category: any) => {
		setSelectedCategory(category);
		setSelectedSubcategory(undefined);
		getSubCategory(category)
	};

	const handleSubCategoryChange = (sub: any) => {
		//setSelectedCategory(category);
		setSelectedSubcategory(sub);
		getProducts(sub, selectedCategory);
	};

	const handleView = (url: string) => {
		navigate(`/product/${url}`);
	};

	const handleAdd = (productId: number, ingredient: any) => {
		// if (!userId) {
		// 	console.error("User ID is not available");
		// 	setIsModalOpen(true);
		// 	return;
		// }
		if (guestId && storedCode) {
			//const newQuantity = (prev[productId] || 0) + 1;
			const request = {
				// group: `Shop- -1`,
				group: `Shop-0`,
				country: storedCode,
				items: [
					{
						...ingredient,
						selected: 1,
					},
				],
			};

			dispatch(addUpdateItemAsync({ id: guestId, request }))
				.then(() => {
					dispatch(getItemsByIdAsync({ id: guestId, country: storedCode }));
					navigate(`/cart`);
				});
		}

	};

	// if (vendorDetails && vendorDetails.country !== storedCode) {
	// 	return (
	// 		<center>

	// 		</center>
	// 	)
	// }

	return (
		<div>
			<div className={`flex flex-col ${isSmallScreen ? 'h-screen' : ''} w-screen mt-0 bg-[#FBFBFB] overflow-hidden`}>
				{storedCode === "IN" ? (
					<>
						<div className={`flex-grow p-4 sm:p-6 lg:p-8 ${isSmallScreen ? 'overflow-hidden' : 'overflow-auto'}`}>

							<div className="mb-4">
								<h2 className="text-xl sm:text-2xl font-semibold text-black">Shop from {vendorDetails?.name}</h2>
							</div>

							<div className="flex justify-between mb-4 items-center">

							</div>



							{/* Categories section */}

							<div className={`mb-4 relative bg-white rounded-lg ${isSmallScreen ? 'py-1' : 'py-1 md:py-2'} ${isSmallScreen ? 'w-full sticky top-20 z-10' : ''}`}>
								<div
									ref={categorySliderRef}
									className="flex overflow-x-auto px-2 scrollbar-hide"
								>
									<div className={`flex ${isSmallScreen ? 'space-x-2 pr-3' : 'space-x-4 p-4 md:p-4'} bg-white rounded-lg`}> {/* Reduced height for larger screens */}
										{categoriesDetail && categoriesDetail.map((category: any) => (
											<button
												key={category.category}
												className={`flex flex-col items-center ${isSmallScreen ? 'p-1' : 'p-2'} rounded-lg transition-all mx-2 ${selectedCategory === category.category
													? 'border-2 border-red  shadow-md'
													: 'border-2 border-transparent hover:border-red'
													}`}
												// onClick={() => setSelectedCategory(category.category)}
												onClick={() => handleCategoryChange(category.category)}
											>
												<div className={`${isSmallScreen ? 'w-16 h-16' : 'w-20 h-20'} bg-greytext rounded-lg mb-2 flex items-center justify-center overflow-hidden`}> {/* Adjusted size for small screens */}
													<img src={category.image || placeholder} alt={category.category} className="w-full h-full object-cover" />
												</div>
												<span className="text-xs text-center line-clamp-2">
													{/* {category.category.charAt(0).toUpperCase() + category.category.slice(1)} */}
													{capitalizeWords(category.category)}
												</span>
											</button>
										))}
									</div>
								</div>
								{!isSmallScreen && (
									<>
										<button
											onClick={() => scroll(categorySliderRef, 'left')}
											className="absolute right-auto top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full mr-14 ml-1 shadow-md"
										>
											<ChevronLeft size={20} />
										</button>
										<button
											onClick={() => scroll(categorySliderRef, 'right')}
											className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white rounded-full ml-14 mr-1 shadow-md"
										>
											<ChevronRight size={20} />
										</button>
									</>
								)}
							</div>

							{/* Main content area */}
							<div className={`flex flex-row gap-3 ${isSmallScreen ? '' : 'overflow-y-auto'}`} style={{ height: isSmallScreen ? 'calc(100vh - 250px)' : 'auto' }}>

								{/* Subcategories sidebar */}
								{selectedSubcategory ? (
									<div className={`${isSmallScreen ? 'w-1/6' : 'w-46'} ${isSmallScreen ? 'sticky top-44 self-start' : ''}`}>
										<div
											className="bg-white p-1 rounded-lg shadow-md overflow-y-auto"
											style={{
												maxHeight: isSmallScreen ? 'calc(100vh - 310px)' : 'calc(100vh - 85px)',
											}}

										>
											<div className={`flex flex-col gap-y-5 space-y-2 rounded-lg ${isSmallScreen ? 'bg-white' : 'bg-white mx-auto'}`}>
												{subCategory.map((subCategory: any) => (
													<button
														key={subCategory.sub_category} // Use sub_category for unique keys
														className={`flex flex-col items-center transition-all rounded-lg text-sm ${selectedSubcategory === subCategory.sub_category
															? 'border-2 border-red  shadow-md'
															: 'border-2 border-transparent hover:border-red'
															}`}
														style={{
															margin: '1px',
															padding: '2px',
															borderRadius: '2px',
														}}
														onClick={() => handleSubCategoryChange(subCategory.sub_category)}
													>
														<div className={`${isSmallScreen ? 'w-18 h-18' : 'w-24 h-24 p-2'} bg-greylight rounded-lg mb-2 overflow-hidden`}>
															<img src={subCategory.image || placeholder} alt={subCategory.sub_category} className="w-full h-full object-contain" />
														</div>
														<span className="text-xs text-center text-wrap">
															{/* {subCategory.sub_category.charAt(0).toUpperCase() + subCategory.sub_category.slice(1)} */}
															{capitalizeWords(subCategory.sub_category)}
														</span> {/* Display the sub_category name */}
													</button>
												))}
											</div>
										</div>
									</div>
								) : (<div></div>)}
								{/* Products grid */}
								<div ref={productGridRef} className={`${isSmallScreen ? 'w-5/6' : 'w-full'}`}>
									<div
										className="bg-white py-3 p-2 rounded-lg shadow-md overflow-y-auto"
										style={{
											maxHeight: isSmallScreen ? 'calc(100vh - 310px)' : 'calc(100vh - 85px)',
										}}>
										<div className={`grid ${isSmallScreen ? 'grid-cols-2 sm:grid-cols-3' : 'grid-cols-2 md:grid-cols-3 lg:grid-cols-5'} gap-4 rounded-lg bg-white px-1 py-2 overflow-y-auto`} style={{ height: isSmallScreen ? 'calc(100vh - 250px)' : 'auto', maxHeight: isSmallScreen ? 'calc(100vh - 250px)' : 'none' }}>
											{/* {products.length > 0 ? ( */}
											{products.map((product: any, index: number) => (
												<div key={index} className={`md:max-h-[275px] md:max-w-[240px] max-h-[250px] relative bg-white rounded-lg border cursor-pointer transition-all ${selectedProduct === product.name && product.status === "1.0" ? 'border-2 border-red-500 shadow-md' : 'border-2 border-[#EDEDED] hover:border-red'}`} onClick={() => {
													if (product.status === "1.0") {
														handleView(product.url); setSelectedProduct(product.name);
													}

												}}>
													{/* {product.discount && (
				   <div className={`absolute top-0 right-0 bg-[#54B22C] py-1 px-3 rounded-tr-l rounded-bl-2xl flex items-center justify-center ${isSmallScreen ? 'text-xs' : 'text-sm'}`}>
				   <span className="font-semibold text-center text-[#FFFFFF]">{product.discount}% <br /> OFF</span>
				 </div>)} */}
													<div className="w-full lg:w-full md:h-[150px] bg-[#00000] rounded-lg mb-3 overflow-hidden px-4 py-1">
														<img src={product.image || placeholder} alt={product.name} className="w-full h-full object-contain" />
													</div>
													<div className="border-t border-[#EDEDED] mx-2 mb-2"></div>
													<div>
														<h4 className="font-medium text-xs lg:text-sm md:text-sm mb-1 mx-3 text-[#000000] line-clamp-1 lg:line-clamp-1">
															{/* {product.name.charAt(0).toUpperCase() + product.name.slice(1)} */}
															{capitalizeWords(product.name)}
														</h4>
														{product.weight && <p className="text-xs text-[#222222] mx-3 mb-1">{product.weight}</p>}
														{/* {product.free && <p className="text-sm mx-3 text-[#54B22C] mb-1">{product.free}</p>} */}
														<div className="border-t border-[#EDEDED] mx-3 mt-2"></div>
														<div className="md:flex flex flex-row items-center justify-between">
															<div>
																<span className="font-bold text-xs lg:text-sm ml-3 text-[#3BB77E]">{storedCode === "IN" ? "₹" : "£"}{product.price}</span>
																{/* {product.originalPrice && <span className="text-[#606060] line-through text-xs ml-1 mb-2">₹{product.originalPrice}</span>} */}
															</div>
															<div className="flex justify-center">
																{product.status === "1.0" ? (
																	<button className="border border-green text-green hover:bg-green hover:text-white transition md:mx-4 py-1 my-2 rounded text-xs lg:text-sm mr-2 w-full max-w-xs px-2 md:px-4" onClick={(e) => {
																		e.stopPropagation();
																		handleAdd(product.id, product);
																	}}>Add</button>
																) : <button className="bg-gray-300 cursor-not-allowed opacity-50 md:mx-4 py-1 my-2 rounded text-xs lg:text-sm mr-2 w-full max-w-xs px-2 md:px-4" onClick={(e) => {
																	// e.stopPropagation();
																	// handleAdd(product.id, product);
																}}>Out Of Stock</button>}

															</div>
														</div>


													</div>
												</div>
											))
												// ) : (
												//   <p>No products available for this subcategory.</p>
											}
										</div>
									</div>
								</div>

							</div>

						</div>

						<div className='p-4 sm:p-6 lg:p-8 text-sm'>

							<h3 className='font-sans text-lg font-semibold mb-2'>
								Sold by {vendorDetails?.name}
							</h3>


							<div className='mb-2'>
								<h4 className='font-sans text-sm text-gray-700'>
									Address: {vendorDetails?.address}, {vendorDetails?.city}, Pin: {vendorDetails?.pin_code}
								</h4>
							</div>


							<div>
								<h4 className='font-sans text-sm text-gray-700'>
									Phone: {vendorDetails?.phone}
								</h4>
							</div>
						</div>

					</>
				) : (
					<div className="min-h-screen flex justify-center items-center pb-32 flex-col bg-white">
						<div className="flex flex-col justify-center items-center h-full text-center">


						</div>
						<div


						>
							This Shop is not availble in your country.
						</div>
					</div>
				)}
			</div>
			{isModalOpen && (
				<LoginModal
					onClose={() => {
						setIsModalOpen(false);
					}}
					isOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
				/>
			)}
		</div>
	);
}

export default VendorExplore;