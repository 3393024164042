import React, { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import im1 from "../../../src/assets/images/ChatCircle.png";
import im2 from "../../../src/assets/images/Export.png";
import cameraIcon from "../../../src/assets/images/Camera.png";
import documentIcon from "../../../src/assets/images/Paperclip.png";
import gifIcon from "../../../src/assets/images/Gif.png";
import replyIcon from "../../../src/assets/images/ChatCircle.png";
import profileImage from "../../../src/assets/images/anna1.png";
import profile from "../../../src/assets/images/anna2.png";
import closeIcon from "../../../src/assets/images/X.png";
import deleteIcon from "../../../src/assets/images/delete.png";
import arrowUpIcon from "../../../src/assets/images/ua.png";
import arrowDownIcon from "../../../src/assets/images/da.png";
import { useAppDispatch } from '../../hooks';
import { createCommentAsync, createLikeUnlikeAsync, getCommentByIdAsync, getLikeUnlikeAsync, replyCommentAsync } from '../../services/reducers/reciepeReducer';
import LoginModal from '../Home/LoginModal';

interface Comment {
  id: number;
  user: string;
  text: string;
  likes: number;
  time: Date;
  isLiked: boolean;
  replies: { user: string; text: string; time: Date }[];
  isRepliesVisible: boolean;
}

interface Props {
  blogId: string;
}
const CommentSection: React.FC<Props> = ({ blogId }) => {
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [comments, setComments] = useState<Comment[]>([
    {
      id: 1,
      user: "Anna",
      text: "I am an aspiring blog writer. Do you have any helpful hints for beginners?",
      likes: 2,
      time: new Date(),
      isLiked: false,
      replies: [],
      isRepliesVisible: false
    },
  ]);
  const [newComment, setNewComment] = useState("");
  const [commentCount, setCommentCount] = useState(comments.length);
  const [replyingTo, setReplyingTo] = useState<number | null>(null);
  const [replyText, setReplyText] = useState("");
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const userEmail = localStorage.getItem("userEmail");
  const [commentDetail, setCommentDetail] = useState<any>([]);
  const [commentCounts, setCommentCounts] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const toggleLike = () => {
  //   setLikeCount(liked ? likeCount - 1 : likeCount + 1);
  //   setLiked(!liked);
  // };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCommentSubmit = () => {

    if (newComment.trim()) {
      const request = {
        userid: userId,
        username: userName ? userName : "",
        useremail: userEmail ? userEmail : "",
        postid: blogId,
        comment: newComment
      };

      dispatch(createCommentAsync(request)).then((res) => {
        getComments();
        setComments([
          ...comments,
          {
            id: Date.now(),
            user: "Anna",
            text: newComment,
            likes: 0,
            time: new Date(),
            isLiked: false,
            replies: [],
            isRepliesVisible: false
          },
        ]);
        setNewComment("");
        setCommentCount(commentCount + 1);
      });
    }
  };

  const handleReplySubmit = (commentId: number) => {

    if (replyText.trim()) {
      const request = {
        userid: userId,
        username: userName ? userName : "",
        useremail: userEmail ? userEmail : "",
        postid: blogId,
        comment: replyText,
        parentcommentid: commentId
      };

      dispatch(replyCommentAsync(request)).then((res) => {
        getComments();
        setComments(
          comments.map((comment) =>
            comment.id === commentId
              ? {
                ...comment,
                replies: [
                  ...comment.replies,
                  {
                    user: "Anna",
                    text: replyText,
                    time: new Date(),
                  },
                ],
                isRepliesVisible: true // Make replies visible when a new reply is added
              }
              : comment
          )
        );
        setReplyText("");
        setReplyingTo(null); // Close reply box after submitting
      });
    }
  };

  const handleDeleteReply = (commentId: number, replyIndex: number) => {
    setComments(
      comments.map((comment) =>
        comment.id === commentId
          ? {
            ...comment,
            replies: comment.replies.filter((_, index) => index !== replyIndex),
          }
          : comment
      )
    );
  };

  const handleDeleteComment = (commentId: number) => {
    setComments(comments.filter((comment) => comment.id !== commentId));
    setCommentCount(commentCount - 1);
  };

  const toggleCommentLike = (id: number) => {
    setComments(
      comments.map((comment) =>
        comment.id === id
          ? {
            ...comment,
            isLiked: !comment.isLiked,
            likes: comment.isLiked ? comment.likes - 1 : comment.likes + 1,
          }
          : comment
      )
    );
  };

  const toggleRepliesVisibility = (commentId: number) => {
    setComments(
      comments.map((comment) =>
        comment.id === commentId
          ? {
            ...comment,
            isRepliesVisible: !comment.isRepliesVisible
          }
          : comment
      )
    );
  };

  const getComments = () => {
    if (blogId) {
      dispatch(getCommentByIdAsync(blogId)).then((res) => {
        if (res.payload) {
          setCommentDetail(res.payload?.data);
          setCommentCounts(res.payload?.data?.length)
        }
      });
    }
  };
  useEffect(() => {
    getComments();
    getLikeUnlike();
  }, [blogId]);

  const handleSubmitLike = () => {
    if (!userId) {
      setIsModalOpen(true);
      console.error("User ID is not available");
      return;
    }
    const request = {
      userid: userId,
      postid: String(blogId),
    };

    try {
      const response = dispatch(createLikeUnlikeAsync(request)).then((res) => {
        setLiked(true)
        getLikeUnlike();
      });
    } catch (error) {
      console.error('Error liking/unliking:', error);
    }
  };

  const getLikeUnlike = async () => {
    if (blogId) {
      const res = await dispatch(getLikeUnlikeAsync(blogId));
      if (res.payload) {
        setLikeCount(res.payload.count);
        if (res.payload.count === 0) { setLiked(false); } else {
          setLiked(true);
        }
      }
    };
  }
  // console.log(liked, "liked")
  return (
    <div className="mt-4 relative">
      {/* Like and Comment Section */}
      <div className="flex items-center justify-between py-4">
        <div className="flex items-center space-x-6">
          <div
            onClick={() => handleSubmitLike()}
            className={`flex items-center space-x-1 cursor-pointer ${liked ? 'text-[#E54D40]' : 'text-gray-500'}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill={liked ? 'currentColor' : 'none'}
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4.318 6.318a4.5 4.5 0 016.364 0L12 7.636l1.318-1.318a4.5 4.5 0 116.364 6.364L12 20.364l-7.682-7.682a4.5 4.5 0 010-6.364z"
              />
            </svg>
            <span className="text-sm font-medium text-black">{likeCount} Likes</span>
          </div>

          {/* Comment Button */}
          <div onClick={toggleSidebar} className="flex items-center space-x-1 cursor-pointer">
            <img src={im1} alt="Comment" className="w-6 h-6" />
            <span className="text-sm font-medium text-black">{commentCounts} Comments</span>
          </div>
        </div>

        {/* Share Button */}
        {/* <div className="flex items-center space-x-1 cursor-pointer">
          <img src={im2} alt="Share" className="w-6 h-6" />
          <span className="text-sm font-medium text-black">Share</span>
        </div> */}
      </div>

      {/* Divider Line */}
      <hr className="border-t border-[#CCCCCC]" />

      {/* Sidebar and Overlay */}
      {isSidebarOpen && (
        <>
          {/* Dark overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-10" onClick={toggleSidebar}></div>

          {/* Sidebar */}
          <div className="fixed right-0 top-0 h-full w-96 bg-white shadow-lg z-20 p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold text-black">Comments ({commentCounts})</h2>
              <button onClick={toggleSidebar} className="text-gray-600 hover:text-gray-900">
                <img src={closeIcon} alt="Close" className="w-6 h-6" />
              </button>
            </div>

            {/* Comment Box */}
            <div className="border p-2 shadow-md rounded-lg mb-8">
              <div className="flex items-center space-x-2 mb-2">
                {/* <img src={profileImage} alt="Profile" className="w-8 h-8 rounded-full" /> */}
                <span className="inline-block size-[42px] bg-gray-100 rounded-full overflow-hidden mr-4">
                  <svg
                    className="size-full text-gray-300"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.62854"
                      y="0.359985"
                      width="15"
                      height="15"
                      rx="7.5"
                      fill="white"
                    ></rect>
                    <path
                      d="M8.12421 7.20374C9.21151 7.20374 10.093 6.32229 10.093 5.23499C10.093 4.14767 9.21151 3.26624 8.12421 3.26624C7.0369 3.26624 6.15546 4.14767 6.15546 5.23499C6.15546 6.32229 7.0369 7.20374 8.12421 7.20374Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M11.818 10.5975C10.2992 12.6412 7.42106 13.0631 5.37731 11.5537C5.01171 11.2818 4.69296 10.9631 4.42107 10.5975C4.28982 10.4006 4.27107 10.1475 4.37419 9.94123L4.51482 9.65059C4.84296 8.95684 5.53671 8.51624 6.30546 8.51624H9.95231C10.7023 8.51624 11.3867 8.94749 11.7242 9.62249L11.8742 9.93184C11.968 10.1475 11.9586 10.4006 11.818 10.5975Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
                <span className="font-bold text-md">{userName || userEmail ? userName || userEmail : "Anonymous User"}</span>
              </div>
              <input
                type="text"
                placeholder="Write a comment..."
                className="w-full text-sm text-black outline-none p-2 mb-2"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
              <div className="flex justify-between items-center">
                {/* <div className="flex space-x-2">
                  <img src={cameraIcon} alt="Camera" className="w-5 h-5 cursor-pointer" />
                  <img src={documentIcon} alt="Document" className="w-5 h-5 cursor-pointer" />
                  <img src={gifIcon} alt="GIF" className="w-5 h-5 cursor-pointer" />
                </div> */}
                <button
                  onClick={handleCommentSubmit}
                  disabled={!newComment.trim()}
                  className={`px-4 py-2 text-sm rounded ${newComment.trim() ? 'bg-green hover:bg-green-hover text-white' : 'bg-[#C1C1C1] text-white cursor-not-allowed'}`}
                >
                  Publish
                </button>
              </div>
            </div>

            {/* Comment Count */}
            <div className="text-sm text-black text-md font-semibold mb-2">
              <span>{commentCounts} Comments</span>
            </div>

            {/* Divider Line */}
            <hr className="border-t border-[#F2F2F2] mb-4" />

            {/* Comments Section */}
            <div className="space-y-2">
              {commentDetail.map((comment: any, commentIndex: number) => (
                <div key={comment.id}>
                  <div className="flex items-start space-x-2">
                    {/* <img src={profile} alt="User" className="w-8 h-8 rounded-full" /> */}
                    <span className="inline-block size-[42px] bg-gray-100 rounded-full overflow-hidden mr-4">
                      <svg
                        className="size-full text-gray-300"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.62854"
                          y="0.359985"
                          width="15"
                          height="15"
                          rx="7.5"
                          fill="white"
                        ></rect>
                        <path
                          d="M8.12421 7.20374C9.21151 7.20374 10.093 6.32229 10.093 5.23499C10.093 4.14767 9.21151 3.26624 8.12421 3.26624C7.0369 3.26624 6.15546 4.14767 6.15546 5.23499C6.15546 6.32229 7.0369 7.20374 8.12421 7.20374Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M11.818 10.5975C10.2992 12.6412 7.42106 13.0631 5.37731 11.5537C5.01171 11.2818 4.69296 10.9631 4.42107 10.5975C4.28982 10.4006 4.27107 10.1475 4.37419 9.94123L4.51482 9.65059C4.84296 8.95684 5.53671 8.51624 6.30546 8.51624H9.95231C10.7023 8.51624 11.3867 8.94749 11.7242 9.62249L11.8742 9.93184C11.968 10.1475 11.9586 10.4006 11.818 10.5975Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                    <div>
                      <div className="font-medium text-md text-black">{comment.username || comment.useremail ? comment.username || comment.useremail : "Anonymous User"}</div>
                      <div className="text-[#AEAEAE] text-sm">
                        {formatDistanceToNow(comment.commentdate)} ago
                      </div>
                    </div>
                  </div>
                  <div className="ml-10 text-blacktext-[15px] font-light">{comment.comment}</div>
                  <div className="ml-10 flex items-center space-x-4 text-[#AEAEAE] text-sm">
                    {/* <div
                      className="flex items-center space-x-1 cursor-pointer"
                      onClick={() => toggleCommentLike(comment.id)}
                    >
                      <span className={comment.isLiked ? "text-red-500" : "text-[#AEAEAE]"}>
                        {comment.isLiked ? '❤️' : '🤍'}
                      </span>
                      <span>{comment.likes} Like</span>
                    </div> */}
                    <div
                      className="flex items-center space-x-1 cursor-pointer"
                      onClick={() => setReplyingTo(replyingTo === comment.usercommentid ? null : comment.usercommentid)}
                    >
                      <img src={replyIcon} alt="Reply" className="w-4 h-4" />
                      <span>Reply</span>
                    </div>
                    {/* <div
                      className="flex items-center space-x-1 cursor-pointer"
                      onClick={() => handleDeleteComment(comment.id)}
                    >
                      <img src={deleteIcon} alt="Delete Comment" className="w-4 h-4 text-red-500" />
                      <span className="text-[#AEAEAE]">Delete</span>
                    </div>
                    <div
                      className="flex items-center space-x-1 cursor-pointer"
                      onClick={() => toggleRepliesVisibility(comment.id)}
                    >
                      <img src={comment.isRepliesVisible ? arrowUpIcon : arrowDownIcon} alt={comment.isRepliesVisible ? 'Hide Replies' : 'Show Replies'} className="w-4 h-4" />
                    </div> */}
                  </div>

                  {/* Replies Section */}
                  {comment.replies && (
                    <div className="mt-2 space-y-2 pl-8">
                      {comment.replies.map((reply: any, index: number) => (
                        <div key={index} className="flex items-start space-x-2 text-sm">
                          <img src={profileImage} alt="Profile" className="w-6 h-6 rounded-full" />
                          <div>
                            <div className="flex flex-col">
                              <span className="font-normal">{reply.username || reply.useremail ? reply.username || reply.useremail : "Anonymous User"}</span>
                              <span className="text-[#AEAEAE] text-xs">
                                {formatDistanceToNow(reply.commentdate)} ago
                              </span>
                            </div>
                            <p className="text-black mt-1">{reply.comment}</p>
                          </div>
                          {/* <button
                            onClick={() => handleDeleteReply(comment.id, index)}
                            className="ml-2 text-red-500"
                          >
                            <img src={deleteIcon} alt="Delete" className="w-3 h-3" />
                          </button> */}
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Reply Input */}
                  {replyingTo === comment.usercommentid && (
                    <div className="mt-2 ml-10 flex items-center space-x-2">
                      <input
                        type="text"
                        placeholder="Reply here..."
                        className="w-full outline-none p-2 border border-[#F2F2F2] rounded-lg text-sm"
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                      />
                      <button
                        onClick={() => handleReplySubmit(comment.usercommentid)}
                        disabled={!replyText.trim()}
                        className={`px-[8px] py-[5px] text-sm rounded ${replyText.trim() ? 'bg-green hover:bg-green-hover text-white' : 'bg-gray-300 text-gray-600 cursor-not-allowed'}`}
                      >
                        Add
                      </button>
                    </div>
                  )}

                  {/* Separator Line */}
                  <div className={`mt-4 border-b border-[#F2F2F2] ${comment.replies?.length > 0 ? 'mt-6' : ''}`} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {isModalOpen && (
        <LoginModal
          onClose={() => {
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </div>
  );
};

export default CommentSection;