import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addAddress,
  addTransferCart,
  addUpdateItem,
  cancelByCOD,
  cancelByRozarpay,
  cancelByStripe,
  createByRazorpay,
  createByStripe,
  createComment,
  createLikeUnlike,
  createOrder,
  createPlan,
  createPlanRozarpay,
  createPlanStripe,
  deleteAddress,
  deleteCart,
  getAdressByUserId,
  getAllCoupon,
  getAllTag,
  getBlogById,
  getBlogs,
  getByPersona,
  getCatCousine,
  getCommentById,
  getExoticList,
  getFestiveDishes,
  getItemsById,
  getLikeUnlike,
  getOrderByUserId,
  getProducts,
  getRecipeByCuisine,
  getRecipeById,
  getRecipeByTime,
  getRecipeIngredient,
  getSearchAll,
  getSingleProduct,
  getSubCatByCousineCat,
  getSubscriptionInfo,
  getUserPref,
  getVendor,
  getVendorCategories,
  getVendorProducts,
  getVendorSubCategories,
  getViewCount,
  redeemCoupon,
  replyComment,
  subScribeBasic,
  updateAddress,
  updateUserPref,
} from "../api/recipeApis";

const initialState = {
  isLoading: false,
  isError: false,
  recipeList: [],
  ingList: [],
  festiveList: [],
  recipeDetail: [],
  recipeSearch: {},
  catCousine: [],
  subCouCat: [],
  // itemsList: [],
  itemsList: {
    data: {
      cart: {
        _id: "",
        cart: {},
        lookup: {},
        cart_value: 0,
        __v: 0,
      },
      extras: [],
    },
  },
  addItem: {},
  getOrders: [],
  getAddress: [],
  getSingleDetail: [],
  getSubscri: {},
};

export const getRecipeByTimeAsync = createAsyncThunk(
  "receipe/getbytime",
  async (request: any) => {
    const response = await getRecipeByTime(request);
    return response.data;
  }
);

export const getRecipeIngredientAsync = createAsyncThunk(
  "receipe/getRecipeIngredient",
  async (request: any) => {
    const response = await getRecipeIngredient(request);
    return response.data;
  }
);

export const getRecipeByIdAsync = createAsyncThunk(
  "receipe/getbyid",
  async (request: any) => {
    const response = await getRecipeById(request);
    return response.data;
  }
);

export const createByStripeAsync = createAsyncThunk(
  "pay/createByStripe",
  async (request: any) => {
    const response = await createByStripe(request);
    return response.data;
  }
);

export const createByRazorpayAsync = createAsyncThunk(
  "pay/createByRazorpay",
  async (request: any) => {
    const response = await createByRazorpay(request);
    return response.data;
  }
);

export const getSearchAllAsync = createAsyncThunk(
  "receipe/getSearchAll",
  async (request: any) => {
    const response = await getSearchAll(request);
    return response.data;
  }
);

export const getCatCousineAsync = createAsyncThunk(
  "shop/getCatCousine",
  async (request: any) => {
    const response = await getCatCousine(request);
    return response.data;
  }
);

export const getSubCatByCousineCatAsync = createAsyncThunk(
  "shop/getSubCatByCousineCat",
  async (request: any) => {
    const response = await getSubCatByCousineCat(request);
    return response.data;
  }
);

export const getItemsByIdAsync = createAsyncThunk(
  "receipe/getItemsById",
  async ({ id, country }: { id: string; country: string }) => {
    const response = await getItemsById(id, country);
    return response.data;
  }
);

export const getVendorCatAsync = createAsyncThunk(
  "vendor/categories",
  async ({name} : {name : string}) => {
    const response = await getVendorCategories(name);
    return response.data;
  }
);

export const addTransferCartAsync = createAsyncThunk(
  "addTransferCart",
  async ({ country, request }: { country: string; request: any }) => {
    const response = await addTransferCart(country, request);
    return response.data;
  }
);

export const getVendorSubCatAsync = createAsyncThunk(
  "vendor/subcategories",
  async ({name,category} : {name : string,category:string}) => {
    const response = await getVendorSubCategories(name,category);
    return response.data;
  }
);

export const addUpdateItemAsync = createAsyncThunk(
  "receipe/addUpdateItem",
  async ({ id, request }: { id: string; request: any }) => {
    const response = await addUpdateItem(id, request);
    return response.data;
  }
);

export const createOrderAsync = createAsyncThunk(
  "receipe/createOrder",
  async (request: any) => {
    const response = await createOrder(request);
    return response.data;
  }
);

export const getAdressByUserIdAsync = createAsyncThunk(
  "receipe/getAdressByUserId",
  async (Id: string) => {
    const response = await getAdressByUserId(Id);
    return response.data;
  }
);

export const addAddressAsync = createAsyncThunk(
  "pay/addAddress",
  async (request: any) => {
    const response = await addAddress(request);
    return response.data;
  }
);

export const getOrderByUserIdAsync = createAsyncThunk(
  "receipe/getOrderByUserId",
  async (Id: string) => {
    const response = await getOrderByUserId(Id);
    return response.data;
  }
);

export const getFestiveDishesAsync = createAsyncThunk(
  "festive/dishes",
  async (request: any) => {
    const response = await getFestiveDishes(request);
    return response.data;
  }
)

export const getByPersonaAsync = createAsyncThunk(
  "pay/getByPersona",
  async (request: any) => {
    const response = await getByPersona(request);
    return response.data;
  }
);

export const getProductsAsync = createAsyncThunk(
  "shop/getProducts",
  async ({ country, request }: { country: string; request: any }) => {
    const response = await getProducts(country, request);
    return response.data;
  }
);

export const getVendorProductsAsync = createAsyncThunk(
  "shop/getProducts",
  async ({name ,request }: {name:string,request: any }) => {
    const response = await getVendorProducts(name,request);
    return response.data;
  }
);

export const getSingleProductAsync = createAsyncThunk(
  "shop/getSingleProduct",
  async (request: any) => {
    const response = await getSingleProduct(request);
    return response.data;
  }
);

export const getSubscriptionInfoAsync = createAsyncThunk(
  "sub/getSubscriptionInfo",
  async (request: any) => {
    const response = await getSubscriptionInfo(request);
    return response.data;
  }
);

export const subScribeBasicAsync = createAsyncThunk(
  "sub/subScribeBasic",
  async (request: any) => {
    const response = await subScribeBasic(request);
    return response.data;
  }
);

export const createPlanAsync = createAsyncThunk(
  "sub/createPlan",
  async (request: any) => {
    const response = await createPlan(request);
    return response.data;
  }
);
export const createPlanRozarpayAsync = createAsyncThunk(
  "sub/createPlanRozarpay",
  async (request: any) => {
    const response = await createPlanRozarpay(request);
    return response.data;
  }
);
export const createPlanStripeAsync = createAsyncThunk(
  "sub/createPlanStripe",
  async (request: any) => {
    const response = await createPlanStripe(request);
    return response.data;
  }
);

export const cancelByRozarpayAsync = createAsyncThunk(
  "cancel/cancelByRozarpay",
  async (request: any) => {
    const response = await cancelByRozarpay(request);
    return response.data;
  }
);

export const cancelByStripeAsync = createAsyncThunk(
  "cancel/cancelByStripe",
  async (request: any) => {
    const response = await cancelByStripe(request);
    return response.data;
  }
);

export const cancelByCODAsync = createAsyncThunk(
  "cancel/cancelByCOD",
  async (request: any) => {
    const response = await cancelByCOD(request);
    return response.data;
  }
);

export const getAllCouponAsync = createAsyncThunk(
  "coupon/getAllCoupon",
  async (request: any) => {
    const response = await getAllCoupon(request);
    return response.data;
  }
);

export const redeemCouponAsync = createAsyncThunk(
  "coupon/redeemCoupon",
  async (request: any) => {
    const response = await redeemCoupon(request);
    return response.data;
  }
);

export const deleteAddressAsync = createAsyncThunk(
  "address/deleteAddress",
  async ( id: string) => {
    const response = await deleteAddress(id);
    return response.data;
  }
);

export const deleteCartAsync = createAsyncThunk(
  "cart/deleteCart",
  async ({ id, reqBody }: { id: string, reqBody: any }) => {
    const response = await deleteCart(id, reqBody);
    return response.data;
  }
);

export const updateAddressAsync = createAsyncThunk(
  "address/updateAddress",
  async ({ id, reqBody }: { id: string, reqBody: any }) => {
    const response = await updateAddress(id, reqBody);
    return response.data;
  }
);

export const getExoticListAsync = createAsyncThunk(
  "shop/getExoticList",
  async ( country: string) => {
    const response = await getExoticList(country);
    return response.data;
  }
);

export const getBlogByIdAsync = createAsyncThunk(
  "getBlogById",
  async ( title: string) => {
    const response = await getBlogById(title);
    return response.data;
  }
);

export const getAllTagAsync = createAsyncThunk(
  "getAllTag",
  async ( tag: any) => {
    const response = await getAllTag(tag);
    return response.data;
  }
);

export const getBlogsAsync = createAsyncThunk(
  "getBlogs",
  async (request: any) => {
    const response = await getBlogs(request);
    return response.data;
  }
);

export const createCommentAsync = createAsyncThunk(
  "createComment",
  async (request: any) => {
    const response = await createComment(request);
    return response.data;
  }
);

export const getCommentByIdAsync = createAsyncThunk(
  "getCommentById",
  async ( id: string) => {
    const response = await getCommentById(id);
    return response.data;
  }
);

export const replyCommentAsync = createAsyncThunk(
  "replyComment",
  async (request: any) => {
    const response = await replyComment(request);
    return response.data;
  }
);

export const getVendorAsync = createAsyncThunk(
  "vendor/getVendor",
  async (url: string) => {
    const response = await getVendor(url);
    return response.data
  }
)

export const createLikeUnlikeAsync = createAsyncThunk(
  "createLikeUnlike",
  async (request: any) => {
    const response = await createLikeUnlike(request);
    return response.data;
  }
);

export const getLikeUnlikeAsync = createAsyncThunk(
  "getLikeUnlike",
  async (request: any) => {
    const response = await getLikeUnlike(request);
    return response.data;
  }
);

export const getRecipeByCuisineAsync = createAsyncThunk(
  "recipe/getRecipeByCuisine",
  async (request: any) => {
    const response = await getRecipeByCuisine(request);
    return response.data;
  }
);

export const getViewCountAsync = createAsyncThunk(
  "getLikeUnlike",
  async () => {
    const response = await getViewCount();
    return response.data;
  }
);

export const getUserPrefAsync = createAsyncThunk(
  "user/getPref",
  async (id: string) => {
    const response = await getUserPref(id);
    return response.data;
  }
);

export const updateUserPrefAsync = createAsyncThunk(
  "user/updatePref",
  async (request: any) => {
    const response = await updateUserPref(request);
    return response.data;
  }
);

const reciepeSlice = createSlice({
  name: "receipe",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRecipeByTimeAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getRecipeByTimeAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recipeList = action.payload;
      })
      .addCase(getRecipeByTimeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getRecipeIngredientAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getRecipeIngredientAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ingList = action.payload;
      })
      .addCase(getRecipeIngredientAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getRecipeByIdAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getRecipeByIdAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recipeDetail = action.payload;
      })
      .addCase(getRecipeByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getSearchAllAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getSearchAllAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recipeSearch = action.payload;
      })
      .addCase(getSearchAllAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getCatCousineAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getCatCousineAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.catCousine = action.payload;
      })
      .addCase(getCatCousineAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getSubCatByCousineCatAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getSubCatByCousineCatAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subCouCat = action.payload;
      })
      .addCase(getSubCatByCousineCatAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getItemsByIdAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getItemsByIdAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.itemsList = action.payload;
      })
      .addCase(getItemsByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(addUpdateItemAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(addUpdateItemAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addItem = action.payload;
      })
      .addCase(addUpdateItemAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getOrderByUserIdAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getOrderByUserIdAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getOrders = action.payload;
      })
      .addCase(getOrderByUserIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getAdressByUserIdAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getAdressByUserIdAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getAddress = action.payload;
      })
      .addCase(getAdressByUserIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getSingleProductAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getSingleProductAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getAddress = action.payload;
      })
      .addCase(getSingleProductAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getSubscriptionInfoAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getSubscriptionInfoAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getSubscri = action.payload;
      })
      .addCase(getSubscriptionInfoAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getFestiveDishesAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getFestiveDishesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.festiveList = action.payload;
      })
      .addCase(getFestiveDishesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default reciepeSlice.reducer;
