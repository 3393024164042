import React, { useState, useEffect, useRef } from "react";
import { Search, X, Mic } from "lucide-react";
import { useAppDispatch } from "../../hooks";
import { getSearchAllAsync } from "../../services/reducers/reciepeReducer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import placeholder from '../../../src/assets/images/placeholderImage.png';

interface SearchOption {
  id: number;
  url: string;
  name: string;
  image: string;
  category: "shop" | "recipeKit";
}

const SearchBar: React.FC = () => {
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const dispatch = useAppDispatch();
  const [filteredOptions, setFilteredOptions] = useState<SearchOption[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState<"shop" | "recipeKit">(
    "shop"
  );
  const searchBarRef = useRef<HTMLDivElement>(null);
  const [searchBarWidth, setSearchBarWidth] = useState<number>(0);
  const location = useLocation();
  const navigate = useNavigate();
  const previousLocation = useRef(location.pathname);
  const searchOptions: SearchOption[] = [

  ];

  const storedCode = localStorage.getItem('countryCode');

  const search = (term: string) => {
    setSearchQuery(term)
    dispatch(getSearchAllAsync({
      "country": storedCode,
      "term": term
    })).then((res) => {
      if (res.payload) {
        // console.log(res.payload?.data);
        let results = [] as SearchOption[];
        if (res.payload?.data != null) {
          res.payload.data['products'].forEach(function (product: any) {
            const p: SearchOption = {
              id: product['id'],
              url: product['url'],
              name: product['name'],
              image: product['image'],
              category: "shop"
            }
            results.push(p)
          });

          res.payload.data['recipes'].forEach(function (product: any) {
            const p: SearchOption = {
              id: product['id'],
              url: product['url'],
              name: product['name'],
              // image : product['images'][0],
              image: Array.isArray(product['images']) && product['images'].length > 0
                ? product['images'][0]
                : '',
              category: "recipeKit"
            }
            results.push(p)
          })
        }
        setFilteredOptions(results)
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1024);
      updateSearchBarWidth();
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const filtered = searchOptions.filter((searchDetails) =>
      searchDetails.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchQuery]);

  useEffect(() => {
    updateSearchBarWidth();
  }, [isSearchOpen, isMobile, isTablet]);

  const updateSearchBarWidth = () => {
    if (searchBarRef.current) {
      setSearchBarWidth(searchBarRef.current.offsetWidth);
    }
  };

  const closeSearch = () => {
    setIsSearchOpen(false);
    setSearchQuery("");
  };

  const handleMicClick = () => {
    // console.log("Microphone clicked");
  };

  useEffect(() => {
    if (previousLocation.current !== location.pathname) {
      // window.location.reload();
      previousLocation.current = location.pathname;
    }
  }, [location.pathname]);

  const renderSearchBar = () => (
    <div
      ref={searchBarRef}
      className={`flex items-center bg-[#FAFCFE] border border-[#525252] rounded-lg p-2 ${isMobile ? "w-full" : isTablet ? "w-48" : "w-80"
        }`}
    >
      <Search size={18} className="text-[#525252] mr-2" />
      <input
        type="text"
        placeholder="Search..."
        className="bg-transparent text-black placeholder-[#525252] focus:outline-none w-full"
        value={searchQuery}
        onChange={(e) => search(e.target.value)}
        autoFocus
      />
      <button onClick={closeSearch} className="ml-2">
        <X size={18} className="text-[#525252]" />
      </button>
    </div>
  );

  const renderMobileSearch = () => (
    <div className="flex items-center space-x-2 w-full">
      {renderSearchBar()}
      <button
        onClick={handleMicClick}
        className="p-2 bg-[#FAFCFE] rounded-full flex-shrink-0"
      >
        <Mic size={20} className="text-[#525252]" />
      </button>
    </div>
  );

  const renderCategoryTabs = () => {
    const shopCount = filteredOptions.filter(
      (searchDetails) => searchDetails.category === "shop"
    ).length;
    const recipeKitCount = filteredOptions.filter(
      (searchDetails) => searchDetails.category === "recipeKit"
    ).length;
   
    return (
      <div className="flex space-x-4 mb-2 border-b border-[#525252] shadow-sm">
        <button
          className={`flex items-center text-xs space-x-2 pb-2 ml-1 ${activeCategory === "shop"
              ? "border-b-2 border-red text-red"
              : "text-[#525252]"
            }`}
          onClick={() => setActiveCategory("shop")}
        >
          <span>Shop</span>
          <span className="bg-[#FAFCFE] text-red rounded-sm px-1.5 py-0.5 font-semibold text-[10px]">
            {shopCount}
          </span>
        </button>
        <button
          className={`flex items-center text-xs space-x-2 pb-2 ${activeCategory === "recipeKit"
              ? "border-b-2 border-red text-red"
              : "text-[#525252]"
            }`}
          onClick={() => setActiveCategory("recipeKit")}
        >
          <span>Recipe Kit</span>
          <span className="bg-[#FAFCFE] text-redrounded-sm px-1.5 py-0.5 font-semibold text-[10px]">
            {recipeKitCount}
          </span>
        </button>
      </div>
    );
  };

  const renderSearchResults = () => {
    const displayedOptions = filteredOptions.filter(
      (searchDetails) => searchDetails.category === activeCategory
    );
    // console.log('Search List', displayedOptions);
    return (
      <div
        className="absolute z-50 bg-white rounded-b-lg shadow-md overflow-hidden  left-0 right-0 mt-1"
        style={{ width: `${searchBarWidth}px` }}
      >
        {renderCategoryTabs()}
        <ul className="divide-y divide-[#EAECF0] ">
          {displayedOptions.map((searchDetails) => (
            // <Link to={activeCategory === "shop" ? `/product/${option.url}` : `/detail/${option.url}`} onClick={closeSearch}>
              <li
                key={searchDetails.id}
                className={`flex items-center px-4 py-2 cursor-pointer transition-colors duration-200 ${isMobile || isTablet
                    ? "hover:bg-pink"
                    : "hover:bg-pink"
                  }`}
                  onClick={() => {
                    closeSearch();
                    navigate(
                      activeCategory === "shop" ? `/product/${searchDetails.url}` : `/detail/${searchDetails.url}`,
                      { state: { searchDetails } } 
                    );
                  }}
              >
                <img
                  src={searchDetails.image || placeholder}
                  alt={searchDetails.name}
                  className="w-12 h-12 mr-3 rounded-full"
                />
                <span className="text-sm flex-grow">{searchDetails.name}</span>
              </li>
          ))}
          {displayedOptions.length === 0 && (
            <li className="px-4 py-2 text-sm text-[#525252]">
              No results found
            </li>
          )}
        </ul>
      </div>
      
    );
  };

  return (
    <div>
      <div className="">
        <div className="flex items-center space-x-6">
          {!isMobile && (
            <div className="relative">
              {!isSearchOpen ? (
                <button
                  onClick={() => setIsSearchOpen(true)}
                  className="p-2 bg-[#FAFCFE] rounded-full hover:bg-[#EDEDED] transition-colors"
                >
                  <Search size={20} className="text-black" />
                </button>
              ) : (
                <div className="relative">
                  {renderSearchBar()}
                  {searchQuery && renderSearchResults()}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div className="mt-2 relative">
          {renderMobileSearch()}
          {searchQuery && renderSearchResults()}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
