import React, { useEffect, useState } from 'react';

import icon from "../../../assets/images/icon.svg";
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks';
import { getOrderByUserIdAsync } from '../../../services/reducers/reciepeReducer';
import placeholder from '../../../../src/assets/images/placeholderImage.png';

const DeliveryDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const userId = localStorage.getItem("userId");
  const dispatch = useAppDispatch();
  const [getOrders, setGetOrders] = useState<any>([]);

  useEffect(() => {
    if (userId) {
      dispatch(getOrderByUserIdAsync(userId)).then((res) => {
        if (res.payload) {
          setGetOrders(res.payload?.data);
        }
      });
    }
  }, [userId]);

  const handleView = () => {
    navigate(`/order-cancel/${id}`);
  };

  const trackingSteps = [
    'Order Confirmed',
    'Delivery Agent Assigned',
    'Order Dispatched',
    'Order in Transit',
    'Out for Delivery',
    'Order Delivered',
  ];
  return (
    <div className="min-h-screen flex flex-col font-dm-sans bg-graybg">
      {/* Header */}
      <div className="flex-grow flex flex-col items-center pt-20 px-4 sm:px-6 md:px-8 max-w-[1100px] mx-auto w-full">
        {/* Address */}
        <div className="bg-white p-4 sm:p-6 rounded-lg w-full mb-4">
          <h2 className="text-lg sm:text-xl text-black font-bold mb-2">Delivery Address</h2>
          {getOrders
            .filter((order: any) => order.id === parseInt(id ?? "", 10)) // Ensure comparison works with number or string
            .map((order: any) =>
              <div>
                <p className="font-bold text-greytext">{order?.name}</p>
                <p className="text-greytext">{order?.phone}</p>
                <p className="text-greytext">{order?.shipping_address}</p>
              </div>

            )}
        </div>

        {/* Orders Section */}
        <div className="relative w-full mb-4">
          <button
            className="hidden sm:flex absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-greylight rounded-full w-8 h-8 items-center justify-center"
            onClick={() => document.getElementById('scroll-container')?.scrollBy({ left: -120, behavior: 'smooth' })}>
            &lt;
          </button>
          <div
            id="scroll-container"
            className="snap-x snap-mandatory overflow-x-auto flex space-x-4 bg-white p-4 rounded-lg w-full scroll-smooth"
            style={{ scrollbarWidth: 'none', whiteSpace: 'nowrap' }}
          >
            {/* {[one, two, three, four, five, six, six, six].map((img, index) => (
                  <div key={index} className="snap-center flex-shrink-0">
                    <img src={img} alt={`product-${index}`} className="w-20 h-20 sm:w-24 sm:h-24 object-contain" />
                  </div>
                ))} */}
            {getOrders
              .filter((order: any) => order.id === parseInt(id ?? "", 10))
              .map((order: any) =>
                order.items?.map((item: any) => (
                  <div key={item.id} className="snap-center flex-shrink-0">
                    <img
                      src={item.info?.image || placeholder}
                      alt={`product-${item.id}`}
                      className="w-20 h-20 sm:w-24 sm:h-24 object-contain"
                    />
                  </div>
                ))
              )}
          </div>
          <button
            className="hidden sm:flex absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-greylight rounded-full w-8 h-8 items-center justify-center"
            onClick={() => document.getElementById('scroll-container')?.scrollBy({ left: 120, behavior: 'smooth' })}>
            &gt;
          </button>
        </div>

        {/* Delivery Steps */}
        {/* <div className="bg-white p-4 sm:p-6 rounded-lg w-full mb-4">
              {['Order Confirmed', 'Delivery Agent Assigned', 'Order Dispatched', 'Order in Transit', 'Out for Delivery', 'Order Delivered'].map((step, index) => (
                <div key={index} className="flex items-start ">
                  <div className="flex flex-col items-center mr-3">
                    <img src={icon} alt="icon" className="w-5 h-5 rounded-full" />
                    {index < 5 && <div className="w-px h-10 bg-gray-300 "></div>} 
                  </div>
                  <div>
                    <p className="font-semibold text-black">{step}</p>
                    <p className="text-sm text-[#828282]">May 05, 03:55 PM</p>
                  </div>
                </div>
              ))}
            </div> */}
        <div className="bg-white p-4 sm:p-6 rounded-lg w-full mb-4">
          {getOrders
            .filter((order: any) => order.id === parseInt(id ?? "", 10))
            .map((order: any) => {
              const trackingStatus = order.tracking_status;

              const visibleSteps = trackingSteps.slice(0, trackingStatus + 1);

              return visibleSteps.map((step, index) => (
                <div key={index} className="flex items-start">
                  <div className="flex flex-col items-center mr-3">
                    <img
                      src={icon}
                      alt="icon"
                      className={`w-5 h-5 rounded-full ${index <= trackingStatus ? 'border border-green' : 'bg-gray-300'}`}
                    />
                    {index < visibleSteps.length - 1 && (
                      <div className={`w-px h-10 ${index < trackingStatus ? 'bg-green' : 'bg-gray-300'}`}></div>
                    )}
                  </div>
                  <div>
                    <p className="font-semibold text-black">{step}</p>
                    {/* <p className="text-sm text-[#828282]">May 05, 03:55 PM</p>  */}
                  </div>
                </div>
              ));
            })}
        </div>
        {/* Cancel button */}
        <button className="w-full bg-white border border-green text-green font-semibold py-4 rounded-lg shadow hover:bg-red-600 hover:text-white hover:bg-green transition-colors" onClick={handleView}>
          Cancel Order
        </button>
      </div>
    </div>
  );
};


export default DeliveryDetails;