import '../../App.css';
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch } from '../../hooks';
import { addAddressAsync, getAdressByUserIdAsync, updateAddressAsync } from '../../services/reducers/reciepeReducer';
import { Country, State, City } from 'country-state-city';
import Select from 'react-select'; // Import react-select
import { useLocation } from 'react-router-dom';
import im1 from "../../assets/images/cross.png";
import { InputStyle } from '../Home/LoginModal';
import PhoneInput from "react-phone-number-input";

interface AddressModalProps {
  isOpen: boolean;
  onClose: () => void;
  getAddress: any;
  addressId?: string;
  cartAddress?: any;
  address?: any;
  // edit?: boolean;
}

interface OptionType {
  label: string;
  value: string;
}

const AddressModal: React.FC<AddressModalProps> = ({ isOpen, onClose, getAddress, addressId, cartAddress, address }) => {
  const [selectedCountry, setSelectedCountry] = useState<OptionType | null>(null);
  const [selectedState, setSelectedState] = useState<OptionType | null>(null);
  const [cityOptions, setCityOptions] = useState<OptionType[]>([]);
  const [customCity, setCustomCity] = useState('');
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId");
  const [customAddressType, setCustomAddressType] = useState('');
  // const [addressData, setAddressData] = useState<any>(null);
  const location = useLocation();
  const addressData = location.state?.addressData;
   const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState<string | null>(null);
    const userPhone = localStorage.getItem("userPhone") || "";

  const validationSchema = Yup.object().shape({
    street_address: Yup.string().required('Street address is required'),
    //city: Yup.string().required('City is required'),
    //city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    postal_code: Yup.string().required('Postal code is required'),
    country: Yup.string().required('Country is required'),
    address_type: Yup.string().required('Address type is required'),
  });

  useEffect(() => {
    const savedPhoneNumber = localStorage.getItem("userPhone");
    if (savedPhoneNumber) {
      setPhone(savedPhoneNumber);
    }
  }, []);

  const handlePhoneChange = (value: string | undefined) => {
    if (value && value.length > 13) {
      setPhoneError("Phone number cannot exceed 10 digits.");
      setPhone(value.substring(0, 13));
      return;
    }
    // setPhone(value || "");
    setPhoneError(null);
  
    if (value) {
      localStorage.setItem("userPhone", value);
    } else {
      localStorage.removeItem("userPhone");
    }
  };
  
  // const handleSubmit = async (values: any) => {
  //   const request = {
  //     user_id: userId,
  //     street_address: values.street_address,
  //     city: selectedCity(),
  //     state: selectedState?.label || '',
  //     postal_code: values.postal_code,
  //     country: selectedCountry?.label || '',
  //     flat_no: "",
  //     address_type: customAddressType || values.address_type,
  //   };
  //   dispatch(addAddressAsync(request)).then(() => {
  //     getAddress();
  //     onClose();
  //   });

  //   if (addressId) {
  //     dispatch(updateAddressAsync({ id: addressId, reqBody: request })).then((res) => {
  //       if (res.payload?.success) {
  //         dispatch(getAdressByUserIdAsync(addressId)).then((res) => {
  //           if (res.payload) {
  //             cartAddress(res.payload.data);
  //           }
  //         });
  //       }
  //       onClose();
  //     });
  //   } else {
  //     dispatch(addAddressAsync(request)).then(() => {
  //       getAddress();
  //       onClose();
  //     });
  //   }
  // };

  useEffect(() => {
    if (addressId) {
      // console.log('Received Address ID:', addressId);
      dispatch(getAdressByUserIdAsync(addressId))
    }
  }, [addressId]);
  // console.log('Address detail:', addressData, address);

  // const countryOptions = Country.getAllCountries().map((country: any) => ({
  //   value: country.isoCode,
  //   label: country.name,
  // }));
  // const countryOptions = Country.getAllCountries().map((country: any) => ({
  //   value: country.isoCode,
  //   label: country.name,
  // }));
  const countryOptions = [
    { value: 'IN', label: 'India' },
    { value: 'GB', label: 'United Kingdom' },
  ];

  const stateOptions = selectedCountry
    ? State.getStatesOfCountry(selectedCountry.value).map((state: any) => ({
      value: state.isoCode,
      label: state.name,
    }))
    : [];

  useEffect(() => {
    if (selectedCountry) {

      citySelect();
    }
  }, [selectedState, selectedCountry]);

  if (!isOpen) { return null; }

  const citySelect = () => {
    if (selectedCountry) {
      if (selectedCountry.value === 'GB' && selectedState) {
        const cities = City.getCitiesOfState(selectedCountry.value, selectedState.value).map((city: any) => ({
          label: city.name,
          value: city.name,
        }));
        setCityOptions(cities);
      } else if (selectedState) {
        const cities = City.getCitiesOfState(selectedCountry.value, selectedState.value).map((city: any) => ({
          label: city.name,
          value: city.name,
        }));
        setCityOptions(cities);
      } else {
        setCityOptions([]);
      }
    } else {
      setCityOptions([]);
    }
  };

  const selectedCity = () => {
    return customCity || (cityOptions.length > 0 ? cityOptions[0].value : '');
  };
  const initialValues = {
    street_address: address?.street_address || '',
    postal_code: address?.postal_code || '',
    address_type: address?.address_type || '',
    country: address?.country || '',
    state: address?.state || '',
    city: address?.city || '',
    ph: address?.ph || ''
  };
 
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-xl w-full max-w-md sm:max-w-lg lg:max-w-3xl mx-4 relative overflow-y-auto max-h-[90vh]">
     

      <div className="flex justify-between items-center -ml-8 -mr-8 -mt-8 bg-[#FAFCFE] pt-4 pb-4 pl-4 pr-1 rounded-md mb-6 ">
  <h2 className="text-xl font-semibold text-black ml-4 ">
    {addressId ? "Update Address" : "Add New Address"}
  </h2>
  <button onClick={onClose} className="mr-6">
    <img
      src={im1}
      alt="Close"
      className="w-4 h-4 ml-0"
    />
  </button>
</div>


        <Formik
          // enableReinitialize
          // initialValues={{
          //   street_address: addressId ? addressDetail[0].street_address : '',
          //   postal_code: addressId ? addressDetail[0].postal_code :  '',
          //   address_type: addressId ? addressDetail[0].address_type :  '',
          //   country:addressId ?  addressDetail[0].country :  '',
          //   state: addressId ? addressDetail[0].state :  '',
          //   city: addressId ? addressDetail[0].city :  '',
          // }}
          // initialValues={{
          //   street_address: '',
          //   postal_code: '',
          //   address_type: '',
          //   country: '',
          //   state: '',
          //   city: '',
          // }}
          // initialValues={{
          //   street_address: '',
          //   postal_code: '',
          //   address_type: '',
          //   country: '',
          //   state: '',
          //   city: '',
          // }}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const request = {
                user_id: userId,
                street_address: values.street_address,
                city: selectedCity(),
                state: selectedState?.label || '',
                postal_code: values.postal_code,
                country: selectedCountry?.label || '',
                flat_no: "",
                address_type: customAddressType || values.address_type,
                ph: userPhone || '',
              };

              setSubmitting(true);

              if (addressId) {
                await dispatch(updateAddressAsync({ id: addressId, reqBody: request })).unwrap();
              } else {
                await dispatch(addAddressAsync(request)).unwrap();
              }

              getAddress();
              onClose(); 

            } catch (error) {
              console.error('Error during API call:', error);
            } finally {
              // Ensure that we allow form submission again only after everything is done
              setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, setFieldValue, values, isSubmitting, isValid }) => (
            <Form>
              <div className="mb-6">
                <label className="block text-sm text-gray-600 mb-1 font-bold">Username</label>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <Field
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                  />
                  <Field
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                  />
                </div>
              </div>
              {/* {!localStorage.getItem("userPhone") ? ( */}
              <div className="mb-6">
                <label className="block text-sm text-gray-600 mb-1 font-bold">Phone</label>
                <InputStyle>
                  <PhoneInput
                    className="w-full outline-none  bg-none border-2 !border-gray-300 !border-opacity-50 rounded-lg p-3 px-4 text-450 text-black placeholder:text-gray-300 font-normal disabled:bg-gray-200"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="IN"
                    countries={["IN" , "GB"]}
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="enter phone number"
                    onBlur={() => {
                      if (phone && !phoneError) {
                        localStorage.setItem("userPhone", phone);
                      }
                    }}
                  />
                  {phoneError && (
                    <div className="text-red text-200 mt-1 font-sans">{phoneError}</div>
                  )}
                </InputStyle>
              </div>
                {/* ): null} */}
              <div className="mb-6">
                <label className="block text-sm text-gray-600 mb-1 font-bold">Address</label>
                <Field
                  type="text"
                  name="street_address"
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                />
                {/* {errors.street_address && touched.street_address ? (
                  <div className="text-red-600 text-sm">{errors.street_address}</div>
                ) : null} */}
                {errors.street_address && touched.street_address && typeof errors.street_address === 'string'
                  ? errors.street_address
                  : null}
              </div>

              {/* Country Dropdown with Search */}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
                <div>
                  <label className="block text-sm text-gray-600 mb-1 font-bold">Country</label>
                  <Select
                    options={countryOptions}
                    value={selectedCountry}
                    onChange={(option) => {
                      setSelectedCountry(option);
                      setSelectedState(null);
                      setCityOptions([]);
                      setCustomCity('');
                      setFieldValue('country', option?.label || values.country);
                    }}
                    placeholder="Select Country"
                  />
                  {/* {errors.country && touched.country ? (
                    <div className="text-red-600 text-sm">{errors.country}</div>
                  ) : null} */}
                  {errors.country && touched.country && typeof errors.country === 'string'
                    ? errors.country
                    : null}
                </div>

                {/* State Dropdown with Search */}
                <div>
                  <label className="block text-sm text-gray-600 mb-1 font-bold">Region/State</label>
                  <Select
                    options={stateOptions}
                    value={selectedState}
                    onChange={(option) => {
                      setSelectedState(option);
                      setFieldValue('state', option?.label);
                      setCityOptions([]);
                    }}
                    placeholder="Select State"
                    isDisabled={!selectedCountry}
                  />
                  {/* {errors.state && touched.state ? (
                    <div className="text-red-600 text-sm">{errors.state}</div>
                  ) : null} */}
                  {errors.state && touched.state && typeof errors.state === 'string'
                    ? errors.state
                    : null}
                </div>

                {/* City Dropdown with Search */}
                {(selectedState || cityOptions.length === 0) && (
                  <div>
                    <label className="block text-sm text-gray-600 mb-1 font-bold">City</label>
                    <input
                      type="text"
                      value={customCity || values.city}
                      onChange={(e) => setCustomCity(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                      placeholder="Enter your city"
                    />
                    {/* {errors.city && touched.city ? (
                      <div className="text-red-600 text-sm">{errors.city}</div>
                    ) : null} */}
                    {errors.city && touched.city && typeof errors.city === 'string'
                      ? errors.city
                      : null}
                  </div>
                )}

                {/* Postal Code Field */}
                <div>
                  <label className="block text-sm text-gray-600 mb-1 font-bold">Zip Code</label>
                  <Field
                    type="text"
                    name="postal_code"
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                  />
                  {/* {errors.postal_code && touched.postal_code ? (
                    <div className="text-red-600 text-sm">{errors.postal_code}</div>
                  ) : null} */}
                  {errors.postal_code && touched.postal_code && typeof errors.postal_code === 'string'
                    ? errors.postal_code
                    : null}
                </div>
              </div>

              {/* Address Type Dropdown */}
              <div className="mb-6">
                <label className="block text-sm text-gray-600 mb-1 font-bold">Address Type</label>
                <Field
                  as="select"
                  name="address_type"
                  onChange={(e: any) => {
                    setCustomAddressType(e.target.value);
                    setFieldValue('address_type', e.target.value);
                  }}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                >
                  <option value="" label="Select address type" />
                  <option value="Home" label="Home" />
                  <option value="Office" label="Office" />
                </Field>
                {/* {errors.address_type && touched.address_type ? (
                  <div className="text-red-600 text-sm">{errors.address_type}</div>
                ) : null} */}
                {errors.address_type && touched.address_type && typeof errors.address_type === 'string'
                  ? errors.address_type
                  : null}
              </div>

              <button
                type="submit"
                className="w-full bg-green text-white py-2 rounded-md hover:bg-green-hover transition duration-200"
                disabled={isSubmitting || !isValid}
              >
                {addressId ? "Update Address" : "Save Address"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddressModal;