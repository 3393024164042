import { useEffect, useState  } from "react";
import Header from "./components/Home/Header";
import Container from "./components/Container";
import TopHeader from "./components/Home/TopHeader";
import Footer from "./components/Home/Footer";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import HomePage from "./components/Home/HomeIndex";
import PersonaIndex from "./components/Persona/PersonaIndex";
import DetailIndex from "./components/ReceipeDetail/DetailIndex";
import Cart from "./components/ReceipeDetail/Cart";
import ShopScreens from "./components/Shop/ShopScreens";
import ProductPage from "./components/ShopDetail/productPage";
import BlogPage from "./components/Blog/blogPage";
import ProfilePage from "./components/Profile/profileIndex";
import Blog from "./components/Blog/blog";
import Aboutus from "./components/AboutUs/Aboutus";
import PrivacyPolicy from "./components/CompanyFooter/PrivacyPolicy";
import OurStory from "./components/OurStory/OurStory";
import BlogPost from "./components/Blog/blogPost";
import ShopExplore from "./components/Shop/shopExplore";
import PricingPlans from "./components/Subscription/Subscription";
import SearchBar from "./components/Home/SeachBar";
import DeliveryDetails from "./components/Profile/Order/orderDetails";
import RefundScreens from "./components/Profile/Order/orderCancellation";
import ProtectedRoute from './components/ProtectedRoute';
import ChristmasSpecial from "./components/Home/ChristmasSpecial";
import shopviewall from "./components/Shop/shopExplore"
import IngredientShop from "./components/Shop/shopExplore";
import ShippingPolicy from "./components/CompanyFooter/ShippingPolicy";
import CancellationRefunds from "./components/CompanyFooter/CancellationRefunds";
import TermsConditions from "./components/CompanyFooter/TermsConditions";
import OurStoryTwo from "./components/OurStory/ourstorytwo";
import OrderConfirmation from "./components/CheckOut/OrderConfirmation";
import india from './assets/images/india.png';
import uk from './assets/images/uk.png';
import { getGuestUserId, injectClarityScript } from "./utils/common";

import ContactUs from "./components/OurStory/ContactUs";
import ConfirmCancelPopup from "./components/Profile/Order/orderCancelledConfirm";
import VendorExplore from "./components/Vendor/VendorExplore";
import RecipeCuisine from "./components/ReceipeDetail/RecipeCuisine";

interface Country {
  name: string;
  flag: string;
}

const AppContainer = () => {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const location = useLocation();

  const countries: Country[] = [
    { name: 'UK', flag: uk },
    { name: 'India', flag: india }
  ];

  const staticFiles = new Set(["/.well-known/assetlinks.json"])
  const [clickedCountry, setClickedCountry] = useState<string | null>();
  const [countryCode, setCountryCode] = useState<string | null>();
  const storedCountry = localStorage.getItem('selectedCountry');
  const storedCode = localStorage.getItem('countryCode');
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(countries[0]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if(storedCode==null && storedCountry==null){
      const hostname = window.location.hostname;
      if (hostname.endsWith('.in')) {
        setSelectedCountry(countries.find((country) => country.name === 'India') || null);
        setCountryCode('IN');
        localStorage.setItem('selectedCountry', 'India');
        localStorage.setItem('countryCode', 'IN');
      } else {
        setSelectedCountry(countries.find((country) => country.name === 'UK') || null);
        setCountryCode('UK');
        localStorage.setItem('selectedCountry', 'UK');
        localStorage.setItem('countryCode', 'UK');
      }
    }
    
    
    // setIsAuthenticated(accessToken !== null);

  }, []);
  
  let location = useLocation();
  // console.log(location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [location]);

  useEffect(() => {
    injectClarityScript();
  }, []);

  useEffect(() => {
    if (window._hsq) {
      // Track page view for the current path
      window._hsq.push(['setPath', location.pathname]);
      window._hsq.push(['trackPageView']);
    }
  }, [location]);


  const [guestId, setGuestId] = useState<string>("");
  localStorage.setItem('guestId', guestId);
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    if (userId === null) {
      setGuestId(getGuestUserId());
    }
    else {
      setGuestId(userId);
    }
  }, [userId]);

  return (
    <Container>
      <div>
        {/* <Router> */}

        {(!staticFiles.has(location.pathname)) &&
          <>
            <TopHeader />
            <Header />
          </>
        }

        <Routes>
          <Route path="/" element={<HomePage />} />
          
          <Route path="/shop" element={<ShopScreens />} />
          
          <Route path="/valentine-special" element={<ChristmasSpecial />} />

          <Route path="/shop/cuisine/:cuisine" element={<ShopExplore/>} />

          <Route path="/recipe/cuisine/:cuisine" element={<RecipeCuisine/>} />

          <Route path="/vendor/:url" element={<VendorExplore/>} />

          <Route path="/persona/:id" element={<PersonaIndex />} />

          <Route path="/detail/:url" element={<DetailIndex />} />

          <Route path="/cart" element={<Cart />} />

          <Route path="/product/:url" element={<ProductPage />} />

          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/order/:id" element={<DeliveryDetails />} />

          <Route path="/order-cancel/:id" element={<RefundScreens />} />

          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blogPost/:title" element={<Blog />} />
          {/* <Route path="/.well-known/assetlinks.json" element={<AssetLink />} /> */}

          {/* <Route path="/Aboutus" element={<Aboutus />} /> */}

          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

          <Route path="/ShippingPolicy" element={<ShippingPolicy />} />

          <Route path="/CancellationRefunds" element={<CancellationRefunds />} />
          <Route path="/OrderCancelled/:id" element={<ConfirmCancelPopup />} />

          <Route path="/TermsConditions" element={<TermsConditions />} />

          <Route path="/OurStory" element={<OurStoryTwo />} />
          <Route path="/OrderConfirm/:id" element={<OrderConfirmation />} />

          <Route path="/subscription" element={<PricingPlans />} />
          <Route path="/viewall" element={<IngredientShop/>} />
          <Route path="/contact" element={<ContactUs/>} />

          {/* <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/profile" element={<ProfilePage />} />
          </Route>

          
          <Route path="*" element={<Navigate to="/home" />} /> */}
        </Routes>
        {(!staticFiles.has(location.pathname)) &&
          <Footer />
        }
        {/* <ToastContainer /> */}
        {/* </Router> */}
      </div>
    </Container>
  );
};

export default AppContainer;
