import React from 'react';
import category from "../../../src/assets/images/category.png";
import category1 from "../../../src/assets/images/category1.png";
import category2 from "../../../src/assets/images/category2.png";

const FeatureSection: React.FC = () => {
  return (
    <div className="flex justify-around items-start bg-white p-2 mt-1 md:mt-2 lg:mt-10 md:mb-10 lg:mb-14 lg:space-x-5">
      <div className="text-center">


        <img src={category1} alt='category' className='px-8 lg:h-[200px] lg:w-[280px]'/>

        <p className="text-xs lg:text-lg md:text-lg font-semibold mt-2 font-sans">Personalisation</p>
        <p className="text-[7px] md:text-xs lg:text-sm text-gray-600 font-sans">Designed with You in Mind</p>
      </div>
      <div className="text-center">


        <img src={category} alt='category' className='px-8 lg:h-[200px] lg:w-[280px]'/>
        <p className="text-xs lg:text-lg md:text-lg font-semibold mt-2 font-sans">Customisation</p>
        <p className="text-[7px] md:text-xs lg:text-sm text-gray-600 font-sans">Adjust Ingredients to Your Liking</p>
      </div>
      <div className="text-center">

        <img src={category2} alt='category' className='px-8 lg:h-[200px] lg:w-[280px]'/>
        <p className="text-xs lg:text-lg md:text-lg font-semibold mt-2 font-sans">Convenience</p>
        <p className="text-[7px] md:text-xs lg:text-sm text-gray-600 font-sans">Luxurious Comfort, Affordable Price</p>
      </div>
    </div>
  );
};

export default FeatureSection;
