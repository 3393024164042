import React, { useEffect, useState } from "react";
import Foke from "../../../src/assets/images/foke.png";
import Clock from "../../../src/assets/images/Clock.png";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import {
  getRecipeByTimeAsync,
} from "../../services/reducers/reciepeReducer";
import GallerySection from "./SameDishes";
import { capitalizeWords } from "../../utils/common";
import placeholder from '../../../src/assets/images/placeholder.png';

interface FoodItemProps {
  image: string;
  category: string;
  title: string;
  description: string;
  price: string;
  time: string;
  serves: string;
}

const FoodItem: React.FC<FoodItemProps> = ({
  image,
  category,
  title,
  description,
  price,
  time,
  serves,
}) => {
  return (
    <div className="w-full md:max-w-md mx-auto flex flex-row md:block lg:block xl:block p-4">
      <img
        src={image || placeholder}
        alt={title}
        className="lg:w-[400px] lg:h-[180px] xl:w-[450px] xl:h-[260px] md:h-[130px] md:w-[400px] h-[100px] w-[110px] pr-2 max-w-full object-cover mt-3 mb-1 lg:mb-2 rounded-xl"
      />
      <div className="flex flex-col items-start space-y-1 md:block lg:block xl:block">
        <span className="text-[#525252] font-sans text-sm lg:text-md xl:inline-block lg:inline-block md:inline-block hidden">
          {category}
        </span>
        <h3 className="font-bold pt-1 text-black lg:pt-2 font-sans md:text-md lg:text-lg text-xl tracking-wide md:text-sm lg:line-clamp-1">
          {title}
        </h3>
        <p
          className={`text-[12px] lg:text-md font-sans text-black line-clamp-1 ${description?.length > 10 ? "min-h-[15px]" : ""
            }`}
        >
          {description}
        </p>

        <div className="flex justify-start items-center mt-auto gap-8 md:gap-3 lg:py-5 md:py-4 pt-3 pb-1">
          <div className="flex items-center space-x-1 lg:space-x-1">
            <div
              className="lg:w-6 lg:h-6 md:w-4 md:h-4 w-4 h-4 bg-cover bg-no-repeat"
              style={{ backgroundImage: `url(${Foke})` }}
            ></div>
            <span className="text-[#2C2C2C] text-[10px] xl:text-lg lg:text-md md:text-xs font-medium font-sans">
              {serves}
            </span>
          </div>

          <div className="flex items-center space-x-1 lg:space-x-1">
            <div
              className="lg:w-6 lg:h-6 md:w-4 md:h-4 w-4 h-4 bg-cover bg-no-repeat"
              style={{ backgroundImage: `url(${Clock})` }}
            ></div>
            <span className="text-[#2C2C2C] text-[10px] xl:text-lg lg:text-md md:text-xs font-medium font-sans">
              {time}
            </span>
          </div>
        </div>
        <div className="flex justify-between w-full xl:hidden lg:hidden md:hidden mt-1">
          <span className="text-gray-400 font-sans text-sm lg:text-md">
            {category}
          </span>
          <span className="font-semibold">{price}</span>
        </div>
      </div>

      <button className="hidden md:block bg-green hover:bg-green-hover text-xs lg:text-lg md:text-md text-white px-5 lg:px-4 md:px-4 py-2 w-full rounded-lg font-sans">
        Recipe kit from {price} • 1 Day Delivery{" "}
      </button>
    </div>
  );
};

interface ReceipeProps {
  selectedRecipe?: string;
  type: string;
  selectedPersona?: number;
  personaDetails?: any;
  festiveDetails?: any;
  searchDetails?: any;
  cuisineDetails?: any;
  displayedIds?: any;
}
const ReceipeKit: React.FC<ReceipeProps> = ({
  selectedRecipe,
  type,
  selectedPersona,
  personaDetails,
  festiveDetails,
  searchDetails,
  cuisineDetails,
  displayedIds,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [receipeDetails, setReceipeDetails] = useState<any>([]);
  // const [displayedRecipeIds, setDisplayedRecipeIds] = useState<string[]>([]);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // useEffect(() => {
  //   if (type === "Home" && "Persona") {
  //     getRecipeDetails();
  //   }
  // }, [selectedRecipe, type]);
  useEffect(() => {
    if (type === "Persona") {
      setReceipeDetails(personaDetails?.data || []);
    } else if (type === "Home") {
      getRecipeDetails();
    }
    else if (type === "Festival") {
      setReceipeDetails(festiveDetails?.data || []);
    }
    else if (type === "Festival") {
      setReceipeDetails(festiveDetails?.data || []);
    }
    else if (type === "Search") {
      setReceipeDetails(searchDetails?.data || []);
    }
    else if (type === "Cuisine") {
      setReceipeDetails(cuisineDetails?.data || []);
    }
  }, [type, selectedRecipe, personaDetails, festiveDetails, searchDetails, cuisineDetails]);

  const storedCountry = localStorage.getItem('selectedCountry');
  const storedCode = localStorage.getItem('countryCode');

  const getRecipeDetails = () => {
    const formattedRecipe =
      selectedRecipe &&
      selectedRecipe.charAt(0).toLowerCase() + selectedRecipe.slice(1);
    if (formattedRecipe) {
      localStorage.setItem("formattedRecipe", formattedRecipe);
    }

    const request = {
      time: formattedRecipe,
      country: storedCode,
    };
    // console.log("Requesting recipes for country:", storedCountry);

    dispatch(getRecipeByTimeAsync(request)).then((res) => {
      // console.log("API Response:", res.payload);
      if (res.payload) {
        setReceipeDetails(res.payload);
        const ids = res.payload?.data?.slice(0, 3).map((item: any) => item.id);
        // setDisplayedRecipeIds(ids);
      }
    });
  };

  const handleView = (url: string) => {
    // navigate(`/detail/${id}`);
    navigate(`/detail/${url}`, { state: { personaDetails, festiveDetails, searchDetails } });
  };
  return (
    <div className="w-full bg-graybg">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-2 lg:px-10 md:px-8 pb-8 cursor-pointer">
        {type === "Home" &&
          receipeDetails?.data?.slice(0, 3).map((item: any, index: number) => (

            <div >
              <div onClick={() => handleView(item.url)} className="transform transition-transform duration-700 hover:scale-105 hover:translate-y-[-5px] hover:shadow-lg hover:rounded-lg">

                <FoodItem
                  key={index}
                  image={item.images[0] ||placeholder}
                  category={`${capitalizeFirstLetter(item.cuisine)} Cuisine`}
                  title={capitalizeWords(item.name)}
                  description={item.description}
                  price={`${storedCode === "IN" ? "₹" : "£"}${parseInt(item.base_price)}`}
                  time={`Time: ${item?.cook_time?.minutes
                      ? `${item?.cook_time?.minutes} min`
                      : `${item?.cook_time?.hours} hour${item?.cook_time?.hours > 1 ? "s" : ""
                      }`
                    }`}
                  // time={`Time: ${item.cook_time.minutes} min`}
                  serves="Serves: 2-3"
                />
              </div>
              <GallerySection recipeIds={[item.id]} url={[item.url]} countryCode={storedCode} />
            </div>
          ))}
        {type === "Persona" &&
          personaDetails?.data?.map((item: any, index: number) => (
            <div>
              <div key={index} onClick={() => handleView(item.url)} className="transition-transform duration-700 hover:scale-105 hover:translate-y-[-5px] hover:shadow-lg hover:rounded-lg">
                <FoodItem
                  image={item.images[0] || placeholder}
                  category={`${capitalizeFirstLetter(item.cuisine)} Cuisine`}
                  title={capitalizeWords(item.name)}
                  description={item.description}
                  price={`${storedCode === "IN" ? "₹" : "£"}${parseInt(item.base_price)}`}
                  time={`Time: ${item.cook_time?.minutes
                    ? `${item.cook_time?.minutes} min`
                    : `${item.cook_time?.hours} hour${item.cook_time?.hours > 1 ? "s" : ""
                    }`
                  }`}
                  serves="Serves: 2-3"
                />
              </div>
              <GallerySection recipeIds={[item.id]} url={[item.url]} countryCode={storedCode} />
            </div>
          ))}
          {type === "Festival" &&
          festiveDetails?.data?.recipes.map((item: any, index: number) => (
            <div >
              <div key={index} onClick={() => handleView(item.url)} className="transition-transform duration-700 hover:translate-y-[-5px] hover:scale-105 hover:shadow-lg hover:rounded-lg">
                <FoodItem
                  image={item.images[0] || placeholder}
                  category={`${capitalizeFirstLetter(item.cuisine)} Cuisine`}
                  title={item.name}
                  description={item.description}
                  price={`${storedCode === "IN" ?"₹" : "£"}${parseInt(item.base_price)}`}
                  time={`Time: ${
                    item.cook_time?.minutes
                      ? `${item.cook_time.minutes} min`
                      : item.cook_time?.hours
                      ? `${item.cook_time?.hours} hour${item.cook_time?.hours > 1 ? "s" : ""}`
                      : "" 
                  }`}
                  serves="Serves: 2-3"
                />
              </div>
              <GallerySection recipeIds={[item.id]} url={[item.url]} countryCode={storedCode}/>
            </div>
          ))}
          {type === "Search" &&
          searchDetails?.data?.recipes.map((item: any, index: number) => (
            <div >
              <div key={index} onClick={() => handleView(item.url)} className="transition-transform duration-700 hover:translate-y-[-5px] hover:scale-105 hover:shadow-lg hover:rounded-lg">
                <FoodItem
                  image={item.images[0] || placeholder}
                  category={`${capitalizeFirstLetter(item.cuisine)} Cuisine`}
                  title={item.name}
                  description={item.description}
                  price={`${storedCode === "IN" ?"₹" : "£"}${parseInt(item.base_price)}`}
                  time={`Time: ${
                    item?.cook_time?.minutes
                      ? `${item?.cook_time?.minutes} min`
                      : `${item?.cook_time?.hours} hour${
                          item?.cook_time?.hours > 1 ? "s" : ""
                        }`
                  }`}
                  serves="Serves: 2-3"
                />
              </div>
              <GallerySection recipeIds={[item.id]} url={[item.url]} countryCode={storedCode}/>
            </div>
          ))}
          {type === "Cuisine" &&
          cuisineDetails?.data?.map((item: any, index: number) => (
            <div >
              <div key={index} onClick={() => handleView(item.url)} className="transition-transform duration-700 hover:translate-y-[-5px] hover:scale-105 hover:shadow-lg hover:rounded-lg">
                <FoodItem
                  image={item.images[0] || placeholder}
                  category={`${capitalizeFirstLetter(item.cuisine)} Cuisine`}
                  title={item.name}
                  description={item.description}
                  price={`${storedCode === "IN" ?"₹" : "£"}${parseInt(item.base_price)}`}
                  time={`Time: ${
                    item.cook_time?.minutes
                      ? `${item.cook_time.minutes} min`
                      : item.cook_time?.hours
                      ? `${item.cook_time?.hours} hour${item.cook_time?.hours > 1 ? "s" : ""}`
                      : "" 
                  }`}
                  serves="Serves: 2-3"
                />
              </div>
              <GallerySection recipeIds={[item.id]} url={[item.url]} countryCode={storedCode}/>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ReceipeKit;